@import "default-variables";

@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 300;
    src: url("../../static/fonts/Lato-LightItalic.ttf") format("ttf");
}
@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 400;
    src: url("../../static/fonts/Lato-RegularItalic.ttf") format("ttf");
}
@font-face {
    font-family: "Lato";
    font-style: italic;
    font-weight: 700;
    src: url("../../static/fonts/Lato-BoldItalic.ttf") format("ttf");
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 300;
    src: url("../../static/fonts/Lato-Light.ttf") format("ttf");
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    src: url("../../static/fonts/Lato-Regular.ttf") format("ttf");
}
@font-face {
    font-family: "Lato";
    font-style: normal;
    font-weight: 700;
    src: url("../../static/fonts/Lato-Bold.ttf") format("ttf");
}
