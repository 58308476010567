/* -----------------------------------------------
 * 1. Dirs
 * -------------------------------------------- */

$image-path: '/static/ui/img' !default;
$fonts-path: '/static/ui/fonts' !default;

/* -----------------------------------------------
 * 1.1. Bootstrap
 * -------------------------------------------- */

$icon-font-path: '#{$fonts-path}/';
$zindex-modal: 1110; // More than AppBar from Material-ui
$zindex-modal-background: 1109; // More than AppBar from Material-ui

/* -----------------------------------------------
 * 2. Colors
 * -------------------------------------------- */

$color-body-background: #f9f9f9 !default;
$color-body-font: #3e454a !default;
$color-body-font-highlight: #3e454a !default;

$color-header-background: #2e353b !default;
$color-header-background-shade-1: #141e25 !default;
$color-header-font: #f3f3f3 !default;
$color-header-font-highlight: #fff !default;
$color-header-mainNav-font-highlight: #fff !default;
$color-header-font-highlight-shadow: rgba(249, 179, 29, .3) !default;
$color-header-button-highlight: #fff !default;

$shadow-header: none !default;

$color-footer-background: #2e353b !default;
$color-footer-background-shade-1: #141e25 !default;
$color-footer-font: #ddd !default;
$color-footer-links-font: #eee !default;
$color-footer-font-highlight: #fff !default;

$color-tile-background: #fdfdfd !default;
$color-tile-font: #3e454a !default;

$color-tile-secondary-background: #fdfdfd !default;
$color-tile-secondary-font: #3e454a !default;

$color-highlight-primary: #f9b31d !default;
$color-highlight-primary-shade-1: #eaa614 !default;
$color-highlight-primary-shade-2: #dc9a0c !default;
$color-highlight-primary-shade-3: #cd8d04 !default;
$color-highlight-primary-light-1: #ffd373 !default;
$color-highlight-primary-light-2: #ffdf98 !default;
$color-highlight-primary-light-3: #ffecc2 !default;
$color-highlight-primary-RGB: rgb(249, 179, 29) !default;

$color-gray: #e6e6e6 !default;
$color-gray-shade-1: #d4d4d4 !default;
$color-gray-shade-2: #adadad !default;
$color-gray-shade-3: #818181 !default;

$color-progress-now: #f9b31d !default;
$color-progress-past: #999 !default;
$color-progress-future: #ccc !default;
$color-progress-title: #999 !default;

$color-button-background: #f9b31d !default;
$color-button-background-highlight: #eaa614 !default;
$color-button-background-highlight-shade-1: #dc9a0c !default;
$color-button-background-highlight-shade-2: #cd8d04 !default;
$color-button-font: #3e454a !default;
$color-button-font-highlight: #3e454a !default;
$color-button-border: #f9b31d !default;

$color-button-secondary-background: #e6e6e6 !default;
$color-button-secondary-background-highlight: #d4d4d4 !default;
$color-button-secondary-background-highlight-shade-1: #adadad !default;
$color-button-secondary-background-highlight-shade-2: #818181 !default;
$color-button-secondary-font: #3e454a !default;
$color-button-secondary-font-highlight: #3e454a !default;

$color-form-background: #fdfdfd !default;
$color-form-background-highlight: #fff !default;
$color-form-background-disabled: #eee !default;
$color-form-font: #555 !default;
$color-form-font-highlight: #3e454a !default;
$color-form-border: #ccc !default;
$color-form-placeholder: #666 !default;

$color-message-default-dark: #3e454a !default;
$color-message-default-medium: #818181 !default;
$color-message-default-light: #fdfdfd !default;
$color-message-default-icon: #ccc !default;
$color-message-help-dark: #3e454a !default;
$color-message-help-medium: #818181 !default;
$color-message-help-light: #ddd !default;
$color-message-help-icon: #ccc !default;
$color-message-error-dark: #a94442 !default;
$color-message-error-medium: #ebccd1 !default;
$color-message-error-light: #f2dede !default;
$color-message-error-icon: #ec2208 !default;
$color-message-warning-dark: #8a6d3b !default;
$color-message-warning-medium: #faebcc !default;
$color-message-warning-light: #fcf8e3 !default;
$color-message-warning-icon: #fd9600 !default;
$color-message-success-dark: #3c763d !default;
$color-message-success-medium: #d6e9c6 !default;
$color-message-success-light: #dff0d8 !default;
$color-message-success-icon: #479030 !default;

$color-tooltip-default-background: #2e353b !default;
$color-tooltip-default-font: #fff !default;

$color-finance-balance-negative: #ec2208 !default;

$color-hr-top: #ddd !default;
$color-hr-bottom: #eee !default;

/* -----------------------------------------------
 * 3. Typography
 * -------------------------------------------- */

$typography-body-size: 10px !default; /* base unit of everything - do not change, please */
$typography-body-weight: 400 !default;
$typography-body-family: Helvetica, Arial, sans-serif !default;

$typography-main-weight: 400 !default;
$typography-main-weight-bold: 700 !default;
$typography-main-weight-light: 300 !default;

$typography-main-size: 1.4em !default;
$typography-main-size-px: 14px !default;
$typography-main-lineHeight-rel: 1.5 !default;
$typography-main-lineHeight-px: 21px !default; /* all (!) block heights should be an integer multiple of this line height */
$typography-main-margin: 0 0 1.5em !default;

$typography-main-lineHeight-half-min-px: 10px !default;
$typography-main-lineHeight-half-max-px: 11px !default;

$typography-sm-size: 1.3em !default;
$typography-sm-size-px: 13px !default;
$typography-sm-lineHeight-rel: 1.615384615 !default;
$typography-sm-lineHeight-small-rel: 1.461538462 !default;
$typography-sm-margin: 0 0 1.615384615em !default;
$typography-sm-margin-small: 0 0 1.461538462em !default;

$typography-xs-size: 1.2em !default;
$typography-xs-size-px: 12px !default;
$typography-xs-lineHeight-rel: 1.75 !default;
$typography-xs-lineHeight-extraSmall-rel: 1.5 !default;
$typography-xs-margin: 0 0 1.75em !default;
$typography-xs-margin-extraSmall: 0 0 1.5em !default;

$typography-header-nav-size: 1.6em !default;
$typography-header-nav-weight: 700 !default;
$typography-header-nav-family: Helvetica, Arial, sans-serif !default;

$typography-foldOut-size: 1.4em !default;

$typography-heading-1-size: 2.8em !default;
$typography-heading-1-lineHeight: 1.5 !default;
$typography-heading-1-margin: 0 0 .75em !default;
$typography-heading-1-family: Helvetica, Arial, sans-serif !default;
$typography-heading-1-weight: 700 !default;
$typography-heading-1-align: left !default;
$typography-heading-2-size: 2.4em !default;
$typography-heading-2-lineHeight: 1.75 !default;
$typography-heading-2-margin: 0 0 .875em !default;
$typography-heading-2-family: Helvetica, Arial, sans-serif !default;
$typography-heading-2-weight: 700 !default;
$typography-heading-2-align: left !default;
$typography-heading-3-size: 1.8em !default;
$typography-heading-3-lineHeight: 1.166666667 !default;
$typography-heading-3-margin: 0 0 1.166666667em !default;
$typography-heading-3-family: Helvetica, Arial, sans-serif !default;
$typography-heading-3-weight: 700 !default;
$typography-heading-3-align: left !default;

/* Overrides for large screens */

$typography-main-size-large: 1.6em !default;
$typography-main-size-large-px: 16px !default;
$typography-main-lineHeight-large-rel: 1.5 !default;
$typography-main-lineHeight-large-px: 24px !default; /* all (!) block heights should be an integer multiple of this line height */
$typography-main-margin-large: 0 0 1.5em !default;

$typography-main-lineHeight-half-min-large-px: 12px !default;
$typography-main-lineHeight-half-max-large-px: 12px !default;

$typography-sm-size-large: 1.4em !default;
$typography-sm-size-large-px: 14px !default;
$typography-sm-lineHeight-large-rel: 1.714285714 !default;
$typography-sm-lineHeight-small-large-rel: 1.5 !default;
$typography-sm-margin-large: 0 0 1.714285714em !default;
$typography-sm-margin-small-large: 0 0 1.5em !default;

$typography-xs-size-large: 1.3em !default;
$typography-xs-size-large-px: 13px !default;
$typography-xs-lineHeight-large-rel: 1.846153846 !default;
$typography-xs-lineHeight-extraSmall-large-rel: 1.461538462 !default;
$typography-xs-margin-large: 0 0 1.846153846em !default;
$typography-xs-margin-extraSmall-large: 0 0 1.461538462em !default;

$typography-heading-1-size_LG: 3.2em !default;
$typography-heading-1-lineHeight_LG: 1.5 !default;
$typography-heading-1-margin_LG: 0 0 .75em !default;
$typography-heading-1-weight_LG: 700 !default;
$typography-heading-2-size_LG: 2.8em !default;
$typography-heading-2-lineHeight_LG: 1.714285714 !default;
$typography-heading-2-margin_LG: 0 0 .857142857em !default;
$typography-heading-2-weight_LG: 700 !default;
$typography-heading-3-size_LG: 2em !default;
$typography-heading-3-lineHeight_LG: 1.2 !default;
$typography-heading-3-margin_LG: 0 0 1.2em !default;
$typography-heading-3-weight_LG: 700 !default;

/* -----------------------------------------------
 * 4. Shapes
 * -------------------------------------------- */

$base-size-unit: 8px !default;
$border-radius: 0 !default;
$border-radius-large: 0 !default;

$button-border-width: 1px !default;
$button-secondary-border-width: 1px !default;

$title-border-width: 2px !default;
$title-secondary-border-width: 2px !default;

$form-border-width: 2px !default;

/* -----------------------------------------------
 * 5. PDF
 * -------------------------------------------- */

$typography-PDF-family: Helvetica !default;
$typography-PDF-color: #333333 !default;
$typography-PDF-highLight-color: #f9b31d !default;

/* -----------------------------------------------
 * 6. Bootstrap
 * -------------------------------------------- */

$font-size-base: $typography-body-size !default;
