@import "default-variables";
@import "default-fonts";
@import "../general/general-rules";

.btn {
    padding: ((($typography-main-lineHeight-px / 3) - $button-border-width) + 1px) $typography-main-lineHeight-half-max-px ((($typography-main-lineHeight-px / 3) - $button-border-width) - 1px);
}

.btn-default {
    padding: ((($typography-main-lineHeight-px / 3) - $button-secondary-border-width) + 1px) $typography-main-lineHeight-half-max-px ((($typography-main-lineHeight-px / 3) - $button-secondary-border-width) - 1px);
}

@media screen and (min-width: 1200px) {
    .btn {
        padding: (($typography-main-lineHeight-large-px / 3) - $button-border-width) ($typography-main-lineHeight-half-max-large-px + 2px);
    }

    .btn-default {
        padding: (($typography-main-lineHeight-large-px / 3) - $button-secondary-border-width) ($typography-main-lineHeight-half-max-large-px + 2px);
    }
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.table.table-condensed > tbody > tr > td,
.table.table-condensed > tbody > tr > th,
.table.table-condensed > tfoot > tr > td,
.table.table-condensed > tfoot > tr > th,
.table.table-condensed > thead > tr > td,
.table.table-condensed > thead > tr > th {
    padding: 9px 8px 5px;
}
