@import "../variables";

@media print {
    .container-header,
    #navi,
    #breadcrumb,
    .fourColumns .colNumber,
    .partnerWrapper,
    .teaserBadgeWrap,
    .subTeaserBackground ol.subTeaserMenu,
    #footer {
        display: none;
    }

    .fourColumns {
        float: left;
    }

    #nanoRepProxyContainer {
        display: none !important;
    }

    #slides .slide,
    #slides .slide .caption {
    }

    #slides .slide img {
        display: none;
    }

    .csc-textpic-imagewrap {
        float: none !important;
    }

    div {
        margin: 0 !important;
        padding: 0 !important;
        float: none !important;
    }

    img {
        display: none !important;
    }

    img.img-printable {
        display: block !important;
    }

    .print-partial * {
        visibility: hidden;
    }

    .print-partial .print-container,
    .print-partial .print-container * {
        visibility: visible;
    }

    .print-container {
        background-color: #fff;
        height: 100%;
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        margin: 0;
        padding: 50px 10px 10px;
        font-size: 14px;
        line-height: 21px;
        z-index: 12345;
    }
}
