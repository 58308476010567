@import "../variables";

/* -----------------------------------------------
 * 1. Colors
 * -------------------------------------------- */

$color-body-background:                                    #f9f9f9;
$color-body-font:                                          #333;
$color-body-font-highlight:                                #333;

$color-header-background:                                  #f2f2f2;
$color-header-background-shade-1:                          #e3e3e3;
$color-header-font:                                        #768693;
$color-header-font-highlight:                              #566b7d;
$color-header-mainNav-font-highlight:                      #fff;
$color-header-font-highlight-shadow:                       rgba(115, 175, 37, .3);
$color-header-button-highlight:                            #566b7d;

$color-footer-background:                                  #768693;
$color-footer-background-shade-1:                          #566b7d;
$color-footer-font:                                        #ddd;
$color-footer-links-font:                                  #eee;
$color-footer-font-highlight:                              #fff;

$color-tile-background:                                    #f2f2f2;
$color-tile-font:                                          #333;

$color-tile-secondary-background:                          #f2f2f2;
$color-tile-secondary-font:                                #333;

$color-highlight-primary:                                  #73af25;
$color-highlight-primary-shade-1:                          #578e10;
$color-highlight-primary-shade-2:                          #3e6d00;
$color-highlight-primary-shade-3:                          #284600;
$color-highlight-primary-light-1:                          #b5e877;
$color-highlight-primary-light-2:                          #ccf19f;
$color-highlight-primary-light-3:                          #e4f8ca;
$color-highlight-primary-RGB:                              rgb(115, 175, 37);

$color-gray:                                               #e6e6e6;
$color-gray-shade-1:                                       #d4d4d4;
$color-gray-shade-2:                                       #adadad;
$color-gray-shade-3:                                       #818181;

$color-progress-now:                                       #566b7d;
$color-progress-past:                                      #999;
$color-progress-future:                                    #ccc;
$color-progress-title:                                     #999;

$color-button-background:                                  #73af25;
$color-button-background-highlight:                        #578e10;
$color-button-background-highlight-shade-1:                #3e6d00;
$color-button-background-highlight-shade-2:                #284600;
$color-button-font:                                        #f9f9f9;
$color-button-font-highlight:                              #fff;
$color-button-border:                                      #73af25;

$color-button-secondary-background:                        #e6e6e6;
$color-button-secondary-background-highlight:              #d4d4d4;
$color-button-secondary-background-highlight-shade-1:      #adadad;
$color-button-secondary-background-highlight-shade-2:      #818181;
$color-button-secondary-font:                              #333;
$color-button-secondary-font-highlight:                    #333;

$color-form-background:                                    #fdfdfd;
$color-form-background-highlight:                          #fff;
$color-form-background-disabled:                           #eee;
$color-form-font:                                          #555;
$color-form-font-highlight:                                #333;
$color-form-border:                                        #ccc;
$color-form-placeholder:                                   #666;

$color-message-default-dark:                                   #3e454a;
$color-message-default-medium:                                 #818181;
$color-message-default-light:                                  #fdfdfd;
$color-message-error-dark:                                     #a94442;
$color-message-error-medium:                                   #ebccd1;
$color-message-error-light:                                    #f2dede;
$color-message-warning-dark:                                   #8a6d3b;
$color-message-warning-medium:                                 #faebcc;
$color-message-warning-light:                                  #fcf8e3;
$color-message-success-dark:                                   #3c763d;
$color-message-success-medium:                                 #d6e9c6;
$color-message-success-light:                                  #dff0d8;

$color-tooltip-default-background:                                 #768693;
$color-tooltip-default-font:                               #fff;

$color-finance-balance-negative:                           #ec2208;

$color-hr-top:                                             #ddd;
$color-hr-bottom:                                             #eee;

/* -----------------------------------------------
 * 2. Typography
 * -------------------------------------------- */

$typography-body-size:                                         10px; /* base unit of everything - do not change, please */
$typography-body-weight:                                       400;
$typography-body-family:                                       Lato, 'Open Sans', Verdana, sans-serif;

$typography-main-weight:                                       400;
$typography-main-weight-bold:                                  700;
$typography-main-weight-light:                                 300;

$typography-main-size:                                         1.4em;
$typography-main-size-px:                                      14px;
$typography-main-lineHeight-rel:                              1.5;
$typography-main-lineHeight-px:                               21px; /* all (!) block heights should be an integer multiple of this line height */
$typography-main-margin:                                       0 0 1.5em;

$typography-main-lineHeight-half-min-px:                      10px;
$typography-main-lineHeight-half-max-px:                      11px;

$typography-sm-size:                                           1.3em;
$typography-sm-size-px:                                        13px;
$typography-sm-lineHeight-rel:                                1.615384615;
$typography-sm-lineHeight-small-rel:                             1.461538462;
$typography-sm-margin:                                         0 0 1.615384615em;
$typography-sm-margin-small:                                      0 0 1.461538462em;

$typography-xs-size:                                           1.2em;
$typography-xs-size-px:                                        12px;
$typography-xs-lineHeight-rel:                                1.75;
$typography-xs-lineHeight-extraSmall-rel:                             1.5;
$typography-xs-margin:                                         0 0 1.75em;
$typography-xs-margin-extraSmall:                                      0 0 1.5em;

$typography-header-nav-size:                                   1.6em;
$typography-header-nav-weight:                                 700;
$typography-header-nav-family:                                 Lato, 'Open Sans', Verdana, sans-serif;

$typography-heading-1-size:                                    2.8em;
$typography-heading-1-lineHeight:                             1.5;
$typography-heading-1-margin:                                  0 0 .75em;
$typography-heading-1-family:                                  Lato, 'Open Sans', Verdana, sans-serif;
$typography-heading-1-weight:                                  400;
$typography-heading-1-align:                                   center;
$typography-heading-2-size:                                    2.4em;
$typography-heading-2-lineHeight:                             1.75;
$typography-heading-2-margin:                                  0 0 .875em;
$typography-heading-2-family:                                  Lato, 'Open Sans', Verdana, sans-serif;
$typography-heading-2-weight:                                  400;
$typography-heading-2-align:                                   left;
$typography-heading-3-size:                                    1.8em;
$typography-heading-3-lineHeight:                             1.166666667;
$typography-heading-3-margin:                                  0 0 1.166666667em;
$typography-heading-3-family:                                  Lato, 'Open Sans', Verdana, sans-serif;
$typography-heading-3-weight:                                  400;
$typography-heading-3-align:                                   left;

/* Overrides for large screens */

$typography-main-size-large:                                      1.6em;
$typography-main-size-large-px:                                   16px;
$typography-main-lineHeight-large-rel:                           1.5;
$typography-main-lineHeight-large-px:                            24px; /* all (!) block heights should be an integer multiple of this line height */
$typography-main-margin-large:                                    0 0 1.5em;

$typography-main-lineHeight-half-min-large-px:                   12px;
$typography-main-lineHeight-half-max-large-px:                   12px;

$typography-sm-size-large:                                        1.4em;
$typography-sm-size-large-px:                                     14px;
$typography-sm-lineHeight-large-rel:                             1.714285714;
$typography-sm-lineHeight-small-large-rel:                          1.5;
$typography-sm-margin-large:                                      0 0 1.714285714em;
$typography-sm-margin-small-large:                                   0 0 1.5em;

$typography-xs-size-large:                                        1.3em;
$typography-xs-size-large-px:                                     13px;
$typography-xs-lineHeight-large-rel:                             1.846153846;
$typography-xs-lineHeight-extraSmall-large-rel:                          1.461538462;
$typography-xs-margin-large:                                      0 0 1.846153846em;
$typography-xs-margin-extraSmall-large:                                   0 0 1.461538462em;

$typography-heading-1-size_LG:                                 3.2em;
$typography-heading-1-lineHeight_LG:                          1.5;
$typography-heading-1-margin_LG:                               0 0 .75em;
$typography-heading-1-weight_LG:                               300;
$typography-heading-2-size_LG:                                 2.8em;
$typography-heading-2-lineHeight_LG:                          1.714285714;
$typography-heading-2-margin_LG:                               0 0 .857142857em;
$typography-heading-2-weight_LG:                               300;
$typography-heading-3-size_LG:                                 2em;
$typography-heading-3-lineHeight_LG:                          1.2;
$typography-heading-3-margin_LG:                               0 0 1.2em;
$typography-heading-3-weight_LG:                               400;

/* -----------------------------------------------
 * 3. Shapes
 * -------------------------------------------- */

$border-radius:                                          0;
$border-radius-large:                                       0;

$button-border-width:                                    1px;
$button-secondary-border-width:                          1px;

$title-border-width:                                      2px;
$title-secondary-border-width:                            2px;

$form-border-width:                                      2px;

/* -----------------------------------------------
 * 4. PDF
 * -------------------------------------------- */

$typography-PDF-family:                                        Verdana;
$typography-PDF-color:                                           #333333;
$typography-PDF-highLight-color:                                      #73af25;

