@import "../variables";

/* -----------------------------------------------
 * Table of Contents
 * -----------------------------------------------
 *
 * I. Global
 *
 *  1.1 Global Layout
 *  1.1.1 (Bootstrap-) Grid
 *  1.2 Typography
 *  1.3 Global Helpers
 *  1.3.1 Finance Helpers
 *  1.4 UI Elements
 *  1.4.1 Modal Dialogs
 *  1.4.2 Tooltips
 *  1.4.3 Tiles
 *  1.4.4 Notifications
 *  1.4.5 Copying to Clipboard
 *  1.5 Forms
 *  1.5.1 jQuery-UI Datepicker
 *  1.6 Tables
 *  1.6.1 "Scrollable Table"
 *  1.7 Pretty File Widget
 *  1.7.1 Ajax File Widget
 *  1.8 Date Range Picker
 *
 * II. Header & Footer
 *
 *  2.1 Header
 *  2.1.1 Logo
 *  2.1.2 User Area
 *  2.1.3 Fold-out menus
 *  2.1.4 Language Menu
 *  2.2 Footer
 *  2.3 Sub Navigation
 *  2.3.1 Navbar (CLA / cardManagement)
 *  2.3.2 Nav Pills (Viabuy)
 *  2.3.3 Sticky Nav Pills (myAccount)
 *
 * III. Content
 *
 *  3.1 Login
 *  3.1.1 "Activate Login"
 *  3.2 Password
 *  3.3 Card Load Page
 *  3.4 My Card Portal
 *  3.5 Documents Tab
 *  3.6 Glossary
 *  3.7 Order Process
 *  3.7.1 Card Selection Toggle Switch (Renewals)
 *  3.7.2 Verification process
 *  3.8 Source of Funds
 *  3.9 to be deprecated
 *  4.1 Card Load Page - Left Panel
 *  4.2 Card Load Page - SelectCountry
 *  4.3 Card Load Page - Load Card Methods
 *  4.4 Widget Modal
 *  4.5 Generic Selectbox
 *  4.6 Current Address (modal overlay)
 *  4.7 Upload Documents
 *  4.8 Step Counter
 *  4.9 Submit Modal
 * -------------------------------------------- */

/* -----------------------------------------------
 * I. Global
 * -------------------------------------------- */

/* -----------------------------------------------
 * 1.1 Global Layout
 * -------------------------------------------- */

body {
    margin-top: 0;
    background: $color-body-background;
}

html,
body {
    height: 100%;
}

#main-wrap {
    min-height: 100%;
    margin: 0 auto -111px;
    overflow: hidden;
}

@media screen and (max-width: 479px) {
    #main-wrap {
        padding-bottom: 50px;
    }
}

#content-wrap {
    padding: $typography-main-lineHeight-half-max-px 0;
}

#footer {
	min-height: 111px;
}

#push {
	height: 111px;
}

@media screen and (min-width: 768px) {
    #main-wrap {
        margin-bottom: -111px;
    }

    #footer {
        min-height: 111px;
    }

    #push {
        height: 111px;
    }
}

/*
 * 1.1.1 (Bootstrap-) Grid
 */

.container,
.container-fluid {
    padding-left: $typography-main-lineHeight-half-max-px;
    padding-right: $typography-main-lineHeight-half-max-px;
}

.row {
    margin-left: ($typography-main-lineHeight-half-max-px * -1);
    margin-right: ($typography-main-lineHeight-half-max-px * -1);
}

.col-xxs-12,
.col-xxxs-12,
.col-xxxxs-12 {
    float: left;
}

.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-xxs-12, .col-xxxs-12, .col-xxxxs-12 {
    padding-left: $typography-main-lineHeight-half-max-px;
    padding-right: $typography-main-lineHeight-half-max-px;
}

@media screen and (min-width: 360px) {
    #content-wrap {
        padding: 15px 0;
    }

    .container,
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }

    .row {
        margin-left: -15px;
        margin-right: -15px;
    }

    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-xxs-12, .col-xxxs-12, .col-xxxxs-12 {
        padding-left: 15px;
        padding-right: 15px;
    }
}

@media screen and (min-width: 480px) {
    #content-wrap {
        padding: $typography-main-lineHeight-px 0;
    }

    .container,
    .container-fluid {
        padding-left: $typography-main-lineHeight-px;
        padding-right: $typography-main-lineHeight-px;
    }
}

@media screen and (min-width: 768px) {
    .container,
    .container-fluid {
        padding-left: $typography-main-lineHeight-px;
        padding-right: $typography-main-lineHeight-px;
    }

    .row {
        margin-left: $typography-main-lineHeight-px * -1;
        margin-right: $typography-main-lineHeight-px * -1;
    }

    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-xxs-12, .col-xxxs-12, .col-xxxxs-12 {
        padding-left: $typography-main-lineHeight-px;
        padding-right: $typography-main-lineHeight-px;
    }
}

@media screen and (min-width: 1200px) {
    .container,
    .container-fluid {
        padding-left: $typography-main-lineHeight-large-px;
        padding-right: $typography-main-lineHeight-large-px;
    }

    .row {
        margin-left: $typography-main-lineHeight-large-px * -1;
        margin-right: $typography-main-lineHeight-large-px * -1;
    }

    .col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-md-1, .col-md-10, .col-md-11, .col-md-12, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-sm-1, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-1, .col-10, .col-11, .col-12, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-xxs-12, .col-xxxs-12, .col-xxxxs-12 {
        padding-left: $typography-main-lineHeight-large-px;
        padding-right: $typography-main-lineHeight-large-px;
    }
}

@media screen and (min-width: 1300px) {
    .container-fluid {
        padding-left: 3%;
        padding-right: 3%;
    }
}

@media screen and (min-width: 1400px) {
    .container-fluid {
        padding-left: 4%;
        padding-right: 4%;
    }
}

@media screen and (min-width: 1500px) {
    .container-fluid {
        padding-left: 5%;
        padding-right: 5%;
    }
}

@media screen and (min-width: 1600px) {
    .container-fluid {
        padding-left: 6%;
        padding-right: 6%;
    }
}

@media screen and (min-width: 1700px) {
    .container-fluid {
        padding-left: 7%;
        padding-right: 7%;
    }
}

@media screen and (min-width: 1800px) {
    .container-fluid {
        padding-left: 8%;
        padding-right: 8%;
    }
}

@media screen and (min-width: 768px) and (min-height: 600px) {
    #content-wrap {
        padding: ($typography-main-lineHeight-px * 2) 0;
    }
}

@media screen and (min-width: 1200px) and (min-height: 768px) {
    #content-wrap {
        padding: ($typography-main-lineHeight-large-px * 3) 0;
    }
}

@media screen and (max-width: 399px) {
    .col-xxxxs-12 {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 479px) {
    .col-xxxs-12 {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

@media screen and (max-width: 599px) {
    .col-xxs-12 {
        float: none;
        width: 100%;
        margin-left: 0;
        margin-right: 0;
    }
}

html {
    -ms-overflow-style: scrollbar !important;
}

/* -----------------------------------------------
 * 1.2 Typography
 * -------------------------------------------- */

body {
    font-size: $typography-body-size;
    font-family: $typography-body-family;
    font-weight: $typography-body-weight;
    text-rendering: optimizeLegibility;
    color: $color-body-font;
    line-height: $typography-main-lineHeight-rel;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

p,
a.link,
.nav.nav-tabs,
.nav.nav-pills,
.main-font-size {
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    font-weight: $typography-main-weight;
}

.base-font-size {
    font-size: $typography-body-size;
}

p {
    margin: $typography-main-margin;
}

h1 {
    font-size: $typography-heading-1-size;
    line-height: $typography-heading-1-lineHeight;
    margin: $typography-heading-1-margin;
    font-family: $typography-heading-1-family;
    font-weight: $typography-heading-1-weight;
    text-align: $typography-heading-1-align;
}

h2 {
    font-size: $typography-heading-1-size;
    line-height: $typography-heading-1-lineHeight;
    margin: $typography-heading-1-margin;
    font-family: $typography-heading-1-family;
    font-weight: $typography-heading-1-weight;
    text-align: $typography-heading-1-align;
}

h3 {
    font-size: $typography-heading-2-size;
    line-height: $typography-heading-2-lineHeight;
    margin: $typography-heading-2-margin;
    font-family: $typography-heading-2-family;
    font-weight: $typography-heading-2-weight;
    text-align: $typography-heading-2-align;
}

h4 {
    font-size: $typography-heading-2-size;
    line-height: $typography-heading-2-lineHeight;
    margin: $typography-heading-2-margin;
    font-family: $typography-heading-2-family;
    font-weight: $typography-heading-2-weight;
    text-align: $typography-heading-2-align;
}

h5 {
    font-size: $typography-heading-3-size;
    line-height: $typography-heading-3-lineHeight;
    margin: $typography-heading-3-margin;
    font-family: $typography-heading-3-family;
    font-weight: $typography-heading-3-weight;
    text-align: $typography-heading-3-align;
}

h6 {
    font-size: $typography-heading-3-size;
    line-height: $typography-heading-3-lineHeight;
    margin: $typography-heading-3-margin;
    font-family: $typography-heading-3-family;
    font-weight: $typography-heading-3-weight;
    text-align: $typography-heading-3-align;
}

a,
a:link,
a:hover,
a:active,
a:visited,
a:focus {
    color: inherit;
    text-decoration: none;
    outline: 0;
}

a,
button,
input[type=submit] {
    touch-action: manipulation;
    transition: color .3s ease, border-color .3s ease, background-color .3s ease, text-shadow .3s ease;
}

p a,
a.link,
ul.text-list a,
ul.documents-list a {
    border-bottom: 1px dotted $color-body-font;
}

p a:hover,
p a:focus,
p a:active,
a.link:hover,
a.link:focus,
a.link:active,
ul.text-list a:hover,
ul.text-list a:focus,
ul.text-list a:active,
ul.documents-list a:hover,
ul.documents-list a:focus,
ul.documents-list a:active {
    color: $color-highlight-primary;
    border-color: $color-highlight-primary;
}

ul.text-list,
ol.text-list {
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    padding: 0;
    margin: 0 0 $typography-main-lineHeight-px $typography-main-lineHeight-px;
}

ul.footnote-list,
ol.footnote-list {
    font-size: $typography-xs-size;
    line-height: $typography-xs-lineHeight-extraSmall-rel;
    padding: 0;
    margin: 0 0 $typography-xs-size $typography-xs-size;
}

p.footnote {
    font-size: $typography-sm-size;
    font-weight: $typography-body-weight;
    line-height: $typography-sm-lineHeight-small-rel;
    margin: $typography-sm-margin-small;
}

p.footnote:last-of-type {
    margin: 0;
}

::selection {
    background: $color-highlight-primary;
    color: #fff;
}

::-moz-selection {
    background: $color-highlight-primary;
    color: #fff;
}

@media screen and (min-width: 1200px) {
    p,
    a.link,
    .nav.nav-tabs,
    .nav.nav-pills,
    .main-font-size {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
    }

    p {
        margin: $typography-main-margin-large;
    }

    h1 {
        font-size: $typography-heading-1-size_LG;
        line-height: $typography-heading-1-lineHeight_LG;
        margin: $typography-heading-1-margin_LG;
        font-weight: $typography-heading-1-weight_LG;
    }

    h2 {
        font-size: $typography-heading-1-size_LG;
        line-height: $typography-heading-1-lineHeight_LG;
        margin: $typography-heading-1-margin_LG;
        font-weight: $typography-heading-1-weight_LG;
    }

    h3 {
        font-size: $typography-heading-2-size_LG;
        line-height: $typography-heading-2-lineHeight_LG;
        margin: $typography-heading-2-margin_LG;
        font-weight: $typography-heading-2-weight_LG;
    }

    h4 {
        font-size: $typography-heading-2-size_LG;
        line-height: $typography-heading-2-lineHeight_LG;
        margin: $typography-heading-2-margin_LG;
        font-weight: $typography-heading-2-weight_LG;
    }

    h5 {
        font-size: $typography-heading-3-size_LG;
        line-height: $typography-heading-3-lineHeight_LG;
        margin: $typography-heading-3-margin_LG;
        font-weight: $typography-heading-3-weight_LG;
    }

    h6 {
        font-size: $typography-heading-3-size_LG;
        line-height: $typography-heading-3-lineHeight_LG;
        margin: $typography-heading-3-margin_LG;
        font-weight: $typography-heading-3-weight_LG;
    }

    ul.text-list,
    ol.text-list {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
        padding: 0;
        margin: 0 0 $typography-main-lineHeight-large-px $typography-main-lineHeight-large-px;
    }

    p.footnote {
        font-size: $typography-sm-size-large;
        line-height: $typography-sm-lineHeight-small-large-rel;
        margin: $typography-sm-margin-small-large;
    }
}

/* -----------------------------------------------
 * 1.3 Global Helpers
 * -------------------------------------------- */

.yellow,
span.yellow,
p.yellow,
a.yellow,
.text-highlight,
span.text-highlight,
p.text-highlight,
a.text-highlight {
    color: $color-highlight-primary;
}

strong,
.text-bold {
    font-weight: $typography-main-weight-bold !important;
}

.text-light {
    font-weight: $typography-main-weight-light !important;
}

.text-capitalize {
    text-transform: capitalize;
}

.text-uppercase {
    text-transform: uppercase;
}

html.no-js .no-js-hide {
    display: none !important;
}

.position-relative,
.rel-pos-div {
    position: relative;
}

.overflow-hidden {
    overflow: hidden;
}

.inline-block,
.display-inline-block {
    display: inline-block;
}

.valign-middle {
    vertical-align: middle;
}

.align-left {
    text-align: left !important;
}

.align-right {
    text-align: right !important;
}

.text-center,
.align-center {
    text-align: center !important;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.float-none {
    float: none;
}

.cursor-pointer {
    cursor: pointer;
}

.mouse-over {
    cursor: pointer;
}

.mouse-over:hover,
.mouse-over:active,
.mouse-over:focus {
    color: $color-highlight-primary;
}

.border-radius-none {
    border-radius: 0 !important;
}

ul.unstyled-list {
    margin: 0;
    padding: 0;
    list-style: none;
}

ul.unstyled-list li {
    padding: 0;
    list-style: none;
}

.ws-nowrap {
    white-space: nowrap;
}

a.shifted-anchor {
    position: relative;
    top: ($typography-main-lineHeight-px * -4);
    float: right;
    padding: 0;
    margin: 0;
    line-height: 1px;
    height: 1px;
}

p#line-height-helper {
    display: none !important;
}

.offscreen {
    position: absolute !important;
    top: auto !important;
    right: 9999px !important;
    bottom: 9999px !important;
    left: auto !important;
    visibility: hidden;
}

.mrg-top-none {
    margin-top: 0 !important;
}

.mrg-top-xs,
.mrg-top-half {
    margin-top: $typography-main-lineHeight-half-min-px !important;
}

.mrg-top-half-alt {
    margin-top: $typography-main-lineHeight-half-max-px !important;
}

.mrg-top-sm,
.mrg-top-single {
    margin-top: $typography-main-lineHeight-px !important;
}

.mrg-top-md,
.mrg-top-lg,
.mrg-top-double {
    margin-top: ($typography-main-lineHeight-px * 2) !important;
}

.mrg-right-none {
    margin-right: 0 !important;
}

.mrg-right-xs,
.mrg-right-half {
    margin-right: $typography-main-lineHeight-half-min-px !important;
}

.mrg-right-sm,
.mrg-right-single {
    margin-right: $typography-main-lineHeight-px !important;
}

.mrg-right-md,
.mrg-right-lg,
.mrg-right-double {
    margin-right: ($typography-main-lineHeight-px * 2) !important;
}

.mrg-btm-none {
    margin-bottom: 0 !important;
}

.mrg-btm-xs,
.mrg-btm-half {
    margin-bottom: $typography-main-lineHeight-half-max-px !important;
}

.mrg-btm-half-alt {
    margin-bottom: $typography-main-lineHeight-half-min-px !important;
}

.mrg-btm-sm,
.mrg-btm-single {
    margin-bottom: $typography-main-lineHeight-px !important;
}

.mrg-btm-md,
.mrg-btm-lg,
.mrg-btm-double {
    margin-bottom: ($typography-main-lineHeight-px * 2) !important;
}

.mrg-left-none {
    margin-left: 0 !important;
}

.mrg-left-xs,
.mrg-left-half {
    margin-left: $typography-main-lineHeight-half-min-px !important;
}

.mrg-left-sm,
.mrg-left-single {
    margin-left: $typography-main-lineHeight-px !important;
}

.mrg-left-md,
.mrg-left-lg,
.mrg-left-double {
    margin-left: ($typography-main-lineHeight-px * 2) !important;
}

.pad-top-none {
    padding-top: 0 !important;
}

.pad-top-half {
    padding-top: $typography-main-lineHeight-half-min-px !important;
}

.pad-top-single {
    padding-top: $typography-main-lineHeight-px !important;
}

.pad-top-double {
    padding-top: ($typography-main-lineHeight-px * 2) !important;
}

.pad-right-none {
    padding-right: 0 !important;
}

.pad-right-half {
    padding-right: $typography-main-lineHeight-half-min-px !important;
}

.pad-right-single {
    padding-right: $typography-main-lineHeight-px !important;
}

.pad-right-double {
    padding-right: ($typography-main-lineHeight-px * 2) !important;
}

.pad-btm-none {
    padding-bottom: 0 !important;
}

.pad-btm-half {
    padding-bottom: $typography-main-lineHeight-half-max-px !important;
}

.pad-btm-half-alt {
    padding-bottom: $typography-main-lineHeight-half-min-px !important;
}

.pad-btm-single {
    padding-bottom: $typography-main-lineHeight-px !important;
}

.pad-btm-double {
    padding-bottom: ($typography-main-lineHeight-px * 2) !important;
}

.pad-left-none {
    padding-left: 0 !important;
}

.pad-left-half {
    padding-left: $typography-main-lineHeight-half-min-px !important;
}

.pad-left-single {
    padding-left: $typography-main-lineHeight-px !important;
}

.pad-left-double {
    padding-left: ($typography-main-lineHeight-px * 2) !important;
}

@media screen and (min-width: 1200px) {
    a.shifted-anchor {
        top: ($typography-main-lineHeight-large-px * -4);
    }

    .mrg-top-xs,
    .mrg-top-half {
        margin-top: $typography-main-lineHeight-half-min-large-px !important;
    }

    .mrg-top-half-alt {
        margin-top: $typography-main-lineHeight-half-max-large-px !important;
    }

    .mrg-top-sm,
    .mrg-top-single {
        margin-top: $typography-main-lineHeight-large-px !important;
    }

    .mrg-top-md,
    .mrg-top-lg,
    .mrg-top-double {
        margin-top: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .mrg-right-xs,
    .mrg-right-half {
        margin-right: $typography-main-lineHeight-half-min-large-px !important;
    }

    .mrg-right-sm,
    .mrg-right-single {
        margin-right: $typography-main-lineHeight-large-px !important;
    }

    .mrg-right-md,
    .mrg-right-lg,
    .mrg-right-double {
        margin-right: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .mrg-btm-xs,
    .mrg-btm-half {
        margin-bottom: $typography-main-lineHeight-half-max-large-px !important;
    }

    .mrg-btm-sm,
    .mrg-btm-single {
        margin-bottom: $typography-main-lineHeight-large-px !important;
    }

    .mrg-btm-half-alt {
        margin-bottom: $typography-main-lineHeight-half-min-large-px !important;
    }

    .mrg-btm-md,
    .mrg-btm-lg,
    .mrg-btm-double {
        margin-bottom: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .mrg-left-xs,
    .mrg-left-half {
        margin-left: $typography-main-lineHeight-half-min-large-px !important;
    }

    .mrg-left-sm,
    .mrg-left-single {
        margin-left: $typography-main-lineHeight-large-px !important;
    }

    .mrg-left-md,
    .mrg-left-lg,
    .mrg-left-double {
        margin-left: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .pad-top-half {
        padding-top: $typography-main-lineHeight-half-min-large-px !important;
    }

    .pad-top-single {
        padding-top: $typography-main-lineHeight-large-px !important;
    }

    .pad-top-double {
        padding-top: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .pad-right-half {
        padding-right: $typography-main-lineHeight-half-min-large-px !important;
    }

    .pad-right-single {
        padding-right: $typography-main-lineHeight-large-px !important;
    }

    .pad-right-double {
        padding-right: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .pad-btm-half {
        padding-bottom: $typography-main-lineHeight-half-max-large-px !important;
    }

    .pad-btm-single {
        padding-bottom: $typography-main-lineHeight-large-px !important;
    }

    .pad-btm-half-alt {
        padding-bottom: $typography-main-lineHeight-half-min-large-px !important;
    }

    .pad-btm-double {
        padding-bottom: ($typography-main-lineHeight-large-px * 2) !important;
    }

    .pad-left-half {
        padding-left: $typography-main-lineHeight-half-min-large-px !important;
    }

    .pad-left-single {
        padding-left: $typography-main-lineHeight-large-px !important;
    }

    .pad-left-double {
        padding-left: ($typography-main-lineHeight-large-px * 2) !important;
    }
}

@media screen and (max-width: 767px) {
    .text-center-xs {
        float: none !important;
        text-align: center;
    }
}

.visible-xxs-inline,
.visible-xxxs-inline,
.visible-xxxxs-inline,
.visible-xxs-block,
.visible-xxxs-block,
.visible-xxxxs-block {
    display: none;
}

@media screen and (max-width: 399px) {
    .hidden-xxxxs {
        display: none;
    }

    .visible-xxxxs-inline {
        display: inline;
    }

    .visible-xxxxs-block {
        display: block;
    }
}

@media screen and (max-width: 479px) {
    .hidden-xxxs {
        display: none;
    }

    .visible-xxxs-inline {
        display: inline;
    }

    .visible-xxxs-block {
        display: block;
    }
}

@media screen and (max-width: 599px) {
    .hidden-xxs {
        display: none;
    }

    .visible-xxs-inline {
        display: inline;
    }

    .visible-xxs-block {
        display: block;
    }
}

/*
 * 1.3.1 Finance Helpers
 */
.negbalance,
.account-balance.negative {
    color: $color-finance-balance-negative !important;
}

/* -----------------------------------------------
 * 1.4 UI Elements
 * -------------------------------------------- */

hr {
    margin: ($typography-main-lineHeight-px - 1px) 0;
    border-top: 1px solid $color-hr-top;
    border-bottom: 1px solid $color-hr-bottom;
    border-right: 0;
    border-left: 0;
}

.progress-bar-yellow,
.progress-bar-xcard {
    background-color: $color-highlight-primary;
}

.progress-bar {
    font-weight: $typography-main-weight-bold;
}

.progress-text {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    font-size: 12px;
    font-weight: $typography-main-weight-bold;
    text-align: center;
    line-height: $typography-main-lineHeight-px;
    color: $color-body-font;
    text-shadow: -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff, 1px 1px 0 #fff;
}

.spinner {
    margin: $typography-main-lineHeight-px auto 0;
    width: ($typography-main-lineHeight-px * 3);
    text-align: center;
}

.spinner > div {
    width: 18px;
    height: 18px;
    background-color: $color-highlight-primary;
    border-radius: 100%;
    display: inline-block;
    animation: bouncedelay 1.4s infinite ease-in-out both;
}

.spinner .bounce1 {
    animation-delay: -0.32s;
}

.spinner .bounce2 {
    animation-delay: -0.16s;
}

@media screen and (min-width: 1200px) {
    hr {
        margin: ($typography-main-lineHeight-large-px - 1px) 0;
    }

    .progress-text {
        line-height: $typography-main-lineHeight-large-px;
    }

    .spinner {
        margin: $typography-main-lineHeight-large-px auto 0;
        width: ($typography-main-lineHeight-large-px * 3);
    }
}

@keyframes bouncedelay {
    from, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1.0);
    }
}

/*
 * 1.4.1 Modal Dialogs
 */

.modal-content {
    border-radius: $border-radius-large;
    border: 2px solid $color-highlight-primary;
}

.modal-content textarea {
    width: 100%;
    height: auto;
}

@media screen and (min-width: 1200px) {
    .modal-content textarea {
        width: 100%;
    }
}

/*
 * 1.4.2 Tooltips
 */

span.label-tooltip {
    cursor: help;
}

span.tooltip-icon {
    color: $color-tooltip-default-background;
}

.tooltip.in {
    opacity: 1;
}

.tooltip .tooltip-inner {
    color: $color-tooltip-default-font;
    background-color: $color-tooltip-default-background;
}

.tooltip.top .tooltip-arrow {
    border-top-color: $color-tooltip-default-background;
}

.tooltip.right .tooltip-arrow {
    border-right-color: $color-tooltip-default-background;
}

.tooltip.bottom .tooltip-arrow {
    border-bottom-color: $color-tooltip-default-background;
}

.tooltip.left .tooltip-arrow {
    border-left-color: $color-tooltip-default-background;
}

/*
 * 1.4.3 Tiles
 */

.well,
.panel,
.panel-group .panel {
    background: $color-tile-secondary-background;
    color: $color-tile-secondary-font;
    margin: 0 0 $typography-main-lineHeight-px;
    border-radius: $border-radius;
    border: $title-secondary-border-width solid $color-gray-shade-1;
    box-shadow: none;
}

.well-primary,
.panel-primary,
.panel-group .panel-primary {
    background: $color-tile-background;
    color: $color-tile-font;
    border: $title-border-width solid $color-highlight-primary;
}

.well {
    padding: ($typography-main-lineHeight-half-max-px - $title-secondary-border-width) ($typography-main-lineHeight-px * .66666666667 - $title-secondary-border-width) ($typography-main-lineHeight-half-min-px - $title-secondary-border-width);
}

.well-primary {
    padding: ($typography-main-lineHeight-half-max-px - $title-border-width) ($typography-main-lineHeight-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-min-px - $title-border-width);
}

.panel-body {
    padding: $typography-main-lineHeight-half-min-px ($typography-main-lineHeight-px * .66666666667 - $title-secondary-border-width) ($typography-main-lineHeight-half-max-px - $title-secondary-border-width);
}

.panel-primary .panel-body {
    padding: $typography-main-lineHeight-half-min-px ($typography-main-lineHeight-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-max-px - $title-border-width);
}

.panel-heading {
    padding: ($typography-main-lineHeight-half-min-px - $title-secondary-border-width + 2px) ($typography-main-lineHeight-px * .66666666667 - $title-secondary-border-width) ($typography-main-lineHeight-half-max-px - $title-secondary-border-width - 2px);
}

.panel-primary .panel-heading {
    padding: ($typography-main-lineHeight-half-min-px - $title-border-width + 2px) ($typography-main-lineHeight-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-max-px - $title-border-width - 2px);
}

.accordion-item .panel-heading {
    cursor: pointer;
}

.panel-body {
    overflow: hidden;
}

.panel-heading {
    color: $color-tile-secondary-font;
    background: $color-tile-secondary-background;
    border-bottom: $title-secondary-border-width solid $color-gray-shade-1;
}

.panel-primary .panel-heading {
    color: $color-tile-font;
    background: $color-tile-background;
    border-bottom: $title-border-width solid $color-highlight-primary;
}

p.well-like {
    padding-left: $border-radius;
}

.panel-group .panel + .panel {
    margin-top: 0;
}

.panel-group-condensed .panel {
    margin: 0 0 $typography-main-lineHeight-half-min-px;
}

.panel-group-condensed .panel:nth-child(2n+1) {
    margin: 0 0 $typography-main-lineHeight-half-max-px;
}

a.panel-toggle {
    border-bottom: none;
}

a.panel-toggle:hover,
a.panel-toggle:focus,
a.panel-toggle:active {
    border-bottom: none;
    color: inherit;
}

@media screen and (min-width: 1200px) {
    .well,
    .panel,
    .panel-group .panel {
        margin: 0 0 $typography-main-lineHeight-large-px;
    }

    .well {
        padding: ($typography-main-lineHeight-half-max-large-px - $title-secondary-border-width) ($typography-main-lineHeight-large-px * .66666666667 - $title-secondary-border-width) ($typography-main-lineHeight-half-min-large-px - $title-secondary-border-width);
    }

    .well-primary {
        padding: ($typography-main-lineHeight-half-max-large-px - $title-border-width) ($typography-main-lineHeight-large-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-min-large-px - $title-border-width);
    }

    .panel-body {
        padding: $typography-main-lineHeight-half-max-large-px ($typography-main-lineHeight-large-px * .66666666667 - $title-secondary-border-width) ($typography-main-lineHeight-half-min-large-px - $title-secondary-border-width);
    }

    .panel-primary .panel-body {
        padding: $typography-main-lineHeight-half-max-large-px ($typography-main-lineHeight-large-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-min-large-px - $title-border-width);
    }

    .panel-heading {
        padding: ($typography-main-lineHeight-half-max-large-px - $title-secondary-border-width + 1px) ($typography-main-lineHeight-large-px * .66666666667 - $title-secondary-border-width) ($typography-main-lineHeight-half-min-large-px - $title-secondary-border-width - 1px);
    }

    .panel-primary .panel-heading {
        padding: ($typography-main-lineHeight-half-max-large-px - $title-border-width + 1px) ($typography-main-lineHeight-large-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-min-large-px - $title-border-width - 1px);
    }

    .panel-group-condensed .panel {
        margin: 0 0 $typography-main-lineHeight-half-min-large-px;
    }

    .panel-group-condensed .panel:nth-child(2n+1) {
        margin: 0 0 $typography-main-lineHeight-half-max-large-px;
    }
}

/*
 * 1.4.4 Tabular Data
 */

.tabular-data {
    line-height: $typography-main-lineHeight-rel;
    margin: 0;
    padding: $typography-main-lineHeight-half-min-px 0;
    opacity: 0;
}

.tabular-data.js-shown {
    opacity: 1;
}

.tabular-data span.label,
.tabular-data span.value {
    line-height: $typography-main-lineHeight-rel;
    font-size: $typography-main-size;
    padding: 0;
    display: inline-block;
}

.tabular-data .col,
.tabular-data .col.col-label,
.tabular-data .col.col-value {
    padding: 0;
}

.tabular-data .col-label {
    width: 45%;
    text-align: right;
    float: left;
}

.tabular-data .col-value {
    width: 2000%;
    margin-left: 49%;
}

.tabular-data.collapsed .col,
.tabular-data.collapsed .col.col-label,
.tabular-data.collapsed .col.col-value {
    width: 100%;
    margin: 0;
    padding: 0;
    float: none;
    text-align: left;
}

.tabular-data.collapsed span.label {
    text-align: left;
    white-space: normal;
}

.tabular-data .tabular-data-row {
    overflow: hidden;
    margin: $typography-main-lineHeight-half-min-px 0;
}

.tabular-data .tabular-data-row-alt {
    margin-bottom: $typography-main-lineHeight-half-max-px;
}

.tabular-data span.label {
    color: inherit;
    font-weight: $typography-main-weight;
    white-space: nowrap;
}

.tabular-data span.value {
    font-weight: $typography-main-weight-bold;
    word-wrap: break-word;
}

.tabular-data.tabular-data-alt span.label {
    font-weight: $typography-main-weight-bold;
}

.tabular-data.tabular-data-alt span.value {
    font-weight: $typography-main-weight;
}

.tabular-data.tabular-data-centered .col,
.tabular-data.tabular-data-centered .col.col-label,
.tabular-data.tabular-data-centered .col.col-value {
    float: none;
    display: inline-block;
    width: 49%;
    padding: 0 2%;
    margin-left: 0;
    vertical-align: middle;
}

.tabular-data.tabular-data-centered .col-label {
    text-align: right;
}

.tabular-data.tabular-data-centered .col-value {
    text-align: left;
}

.tabular-data.tabular-data-centered span.label {
    text-align: right;
    white-space: normal;
}

.tabular-data-footer {
    padding: ($typography-main-lineHeight-half-min-px * 2) $typography-main-lineHeight-half-min-px;
    background-color: $color-gray;
    text-align: center;
    font-size: $typography-xs-size;
    line-height: $typography-xs-lineHeight-rel;
}

.tabular-data-footer a {
    text-decoration: underline;
}

.tabular-data + .tabular-data-footer {
    border-top: $title-secondary-border-width solid $color-gray-shade-1;
}

@media screen and (min-width: 1200px) {
    .tabular-data {
        line-height: $typography-main-lineHeight-large-rel;
        padding: $typography-main-lineHeight-half-min-large-px 0;
    }

    .tabular-data span.label,
    .tabular-data span.value {
        line-height: $typography-main-lineHeight-large-rel;
        font-size: $typography-main-size-large;
    }

    .tabular-data .tabular-data-row {
        margin: $typography-main-lineHeight-half-min-large-px 0;
    }

    .tabular-data .tabular-data-row-alt {
        margin-bottom: $typography-main-lineHeight-half-max-large-px;
    }

    .tabular-data-footer {
        padding: ($typography-main-lineHeight-half-max-large-px * 2) $typography-main-lineHeight-half-max-large-px;
        text-align: center;
        font-size: $typography-xs-size-large;
        line-height: $typography-xs-lineHeight-large-rel;
    }
}

/*
 * 1.4.5 Notifications
 */

p.error,
.error .help-inline,
.has-error .help-inline {
    color: $color-message-error-dark;
}

.alert {
    margin: 0 0 $typography-main-lineHeight-px;
    padding: ($typography-main-lineHeight-half-max-px - $title-border-width) ($typography-main-lineHeight-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-min-px - $title-border-width);
    border-radius: $border-radius;
    border: $title-border-width solid $color-gray-shade-3;
}

.alert.alert-titled {
    margin: 0 0 $typography-main-lineHeight-px;
    padding: ($typography-main-lineHeight-half-max-px - $title-border-width + 4px) ($typography-main-lineHeight-px * .66666666667 - $title-border-width) ($typography-main-lineHeight-half-min-px - $title-border-width + 1px);
}

.alert.alert-titled h5 {
    margin: 0 0 ($typography-main-lineHeight-px - 5px);
}

.alert.alert-xcard {
    color: $color-message-default-dark;
    border-color: $color-message-default-medium;
    background-color: $color-message-default-light;
}

.alert.alert-success {
    color: $color-message-success-dark;
    border-color: $color-message-success-medium;
    background-color: $color-message-success-light;
}

.alert.alert-warning {
    color: $color-message-warning-dark;
    border-color: $color-message-warning-medium;
    background-color: $color-message-warning-light;
}

.alert.alert-danger {
    color: $color-message-error-dark;
    border-color: $color-message-error-medium;
    background-color: $color-message-error-light;
}

.alert a,
.alert.alert-xcard a {
    font-weight: inherit;
    text-decoration: none;
    color: $color-message-default-dark;
    border-bottom: 1px dotted $color-message-default-dark;
}

.alert.alert-success a {
    color: $color-message-success-dark;
    border-color: $color-message-success-dark;
}

.alert.alert-warning a {
    color: $color-message-warning-dark;
    border-color: $color-message-warning-dark;
}

.alert.alert-danger a {
    color: $color-message-error-dark;
    border-color: $color-message-error-dark;
}

.alert a:hover,
.alert a:focus,
.alert a:active,
.alert.alert-xcard a:hover,
.alert.alert-xcard a:focus,
.alert.alert-xcard a:active,
.alert.alert-success a:hover,
.alert.alert-success a:focus,
.alert.alert-success a:active,
.alert.alert-warning a:hover,
.alert.alert-warning a:focus,
.alert.alert-warning a:active,
.alert.alert-danger a:hover,
.alert.alert-danger a:focus,
.alert.alert-danger a:active {
    color: $color-highlight-primary;
    border-color: $color-highlight-primary;
}

@media screen and (min-width: 1200px) {
    .alert {
        margin: 0 0 $typography-main-lineHeight-large-px;
        padding: ($typography-main-lineHeight-half-min-large-px - $title-border-width) ($typography-main-lineHeight-large-px * .66666667 - $title-border-width) ($typography-main-lineHeight-half-max-large-px - $title-border-width);
    }

    .alert.alert-titled {
        margin: 0 0 $typography-main-lineHeight-large-px;
        padding: ($typography-main-lineHeight-half-min-large-px - $title-border-width + 4px) ($typography-main-lineHeight-large-px * .75 - $title-border-width) ($typography-main-lineHeight-half-max-large-px - $title-border-width + 1px);
    }

    .alert.alert-titled h5 {
        margin: 0 0 ($typography-main-lineHeight-px - 5px);
    }
}

.form-control.has-error {
    background-color: $color-message-error-light;
}

.form-control.has-warning {
    background-color: $color-message-warning-light;
}

.form-control.has-error:focus,
.form-control.has-warning:focus {
    background-color: $color-form-background-highlight;
}

/*
 * 1.4.6 Copying to Clipboard
 */

#clipboard-container {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 0;
    height: 0;
    opacity: 0;
}

#clipboard-container #clipboard {
    width: 1px;
    height: 1px;
    padding: 0;
}

.modal #clipboard {
    width: 100%;
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    height: ($typography-main-lineHeight-px * 4 + 4px);
    padding: $typography-main-lineHeight-half-max-px $typography-main-lineHeight-px $typography-main-lineHeight-half-min-px;
    margin-bottom: 0;
}

@media screen and (min-width: 1200px) {
    .modal #clipboard {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
        height: ($typography-main-lineHeight-large-px * 4 + 4px);
        padding: $typography-main-lineHeight-half-max-large-px $typography-main-lineHeight-large-px $typography-main-lineHeight-half-min-large-px;
    }
}

/* -----------------------------------------------
 * 1.5 Forms
 * -------------------------------------------- */

form {
    margin: 0;
    padding: 0;
}

.form-hidden {
    visibility: hidden;
    position: absolute;
    right: 9999px;
    bottom: 9999px;
}

.form-group,
.form-inline {
    margin-bottom: $typography-main-lineHeight-px;
}

.form-inline .form-group {
    margin-bottom: 0;
}

.form-control,
.form-control:focus {
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    padding: ($typography-main-lineHeight-half-min-px / 2 + 1px) $typography-main-lineHeight-half-max-px ($typography-main-lineHeight-half-min-px / 2);
    margin: ($typography-main-lineHeight-half-min-px / 2 - ($form-border-width * 2 - 2px)) 0;
    height: auto;
    color: $color-form-font;
    background-color: $color-form-background;
    border: $form-border-width solid $color-form-border;
    border-width: $form-border-width !important;
    border-color: $color-form-border !important;
    border-radius: $border-radius;
    box-shadow: none !important;
}

select.form-control,
select.form-control:focus {
    height: $typography-main-lineHeight-px + $typography-main-lineHeight-half-min-px + ($form-border-width * 2);
}

.form-control.form-control-four-digits {
    width: 6em;
}

.form-group .tip {
    color: $color-gray-shade-3;
}

@media screen and (min-width: 1200px) {
    .form-group,
    .form-inline {
        margin-bottom: $typography-main-lineHeight-large-px;
    }

    .form-control,
    .form-control:focus {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
        padding: ($typography-main-lineHeight-half-min-large-px / 2) $typography-main-lineHeight-half-max-large-px ($typography-main-lineHeight-half-min-large-px / 2);
        margin: ($typography-main-lineHeight-half-min-large-px / 2) - ($form-border-width * 2 - 2px) 0;
    }

    select.form-control,
    select.form-control:focus {
        height: $typography-main-lineHeight-large-px + $typography-main-lineHeight-half-min-large-px + ($form-border-width * 2);
    }
}

.form-control:focus {
    color: $color-form-font-highlight;
    background-color: $color-form-background-highlight;
}

.form-control-disabled[style] {
    background-color: $color-form-background-disabled !important;
}

.row-form-inline {
    margin-left: $typography-main-lineHeight-half-min-px / -2;
    margin-right: $typography-main-lineHeight-half-min-px / -2;
}

.row-form-inline > div {
    padding-left: $typography-main-lineHeight-half-min-px / 2;
    padding-right: $typography-main-lineHeight-half-min-px / 2;
}

@media screen and (min-width: 1200px) {
    .row-form-inline {
        margin-left: $typography-main-lineHeight-half-min-large-px / -2;
        margin-right: $typography-main-lineHeight-half-min-large-px / -2;
    }

    .row-form-inline > div {
        padding-left: $typography-main-lineHeight-half-min-large-px / 2;
        padding-right: $typography-main-lineHeight-half-min-large-px / 2;
    }
}

.form-inline .radio + .radio,
.form-inline .checkbox + .checkbox {
    margin-left: 10px;
}

input[type=checkbox],
input[type=radio] {
    margin-right: 4px;
}

label.control-label {
    margin: 0;
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    font-weight: 400;
}

label.checkbox,
label.checkbox-inline,
label.radio,
label.radio-inline {
    font-size: $typography-main-size;
}

@media screen and (min-width: 1200px) {
    label.control-label {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
    }

    label.checkbox,
    label.checkbox-inline,
    label.radio,
    label.radio-inline {
        font-size: $typography-main-size-large;
    }
}

button:hover,
button:active,
button:focus {
    outline: 0;
}

.btn {
    font-weight: $typography-main-weight-bold;
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    padding: ($typography-main-lineHeight-px / 3 - 1px) $typography-main-lineHeight-half-max-px;
    margin: ($typography-main-lineHeight-px / 3) 0 0;
    border-radius: $border-radius;
    transition: color .3s ease, border-color .3s ease, background-color .3s ease;
}

.edit-btn {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(50%, -50%);

    width: 44px;
    height: 44px;
    min-width: 0 !important;
    margin: 0;
    padding: 10px;
    border-radius: 50%;

    color: $color-button-font;
    background-color: $color-button-background;
}

.btn-alt,
.btn-top,
.btn-toolbar-alt .btn {
    margin: 0 0 ($typography-main-lineHeight-px / 3);
}

@media screen and (min-width: 480px) {
    .btn {
        min-width: 100px;
    }
}

.yellowBtn,
.btn-xcard-main {
    padding: ($typography-main-lineHeight-px / 3 - $button-border-width) $typography-main-lineHeight-half-max-px;
    color: $color-button-font;
    border: $button-border-width solid $color-button-border;
    border-radius: $border-radius;
    background-color: $color-button-background;
}

.yellowBtn:hover,
.btn-xcard-main:hover {
    color: $color-button-font-highlight;
    background-color: $color-button-background-highlight;
    border-color: $color-button-background-highlight-shade-1;
}

.yellowBtn:focus,
.btn-xcard-main:focus {
    color: $color-button-font-highlight;
    background-color: $color-button-background-highlight;
    border-color: $color-button-background-highlight-shade-2;
}

.yellowBtn:active,
.btn-xcard-main:active,
.yellowBtn:active:focus,
.btn-xcard-main:active:focus,
.yellowBtn:active:hover,
.btn-xcard-main:active:hover {
    color: $color-button-font-highlight;
    background-color: $color-button-background-highlight-shade-1;
    border-color: $color-button-background-highlight-shade-2;
}

.btn-default {
    font-weight: $typography-main-weight-bold;
    padding: ($typography-main-lineHeight-px / 3 - $button-secondary-border-width) $typography-main-lineHeight-half-max-px;
    color: $color-button-secondary-font;
    background-color: $color-button-secondary-background;
    border: $button-secondary-border-width solid $color-button-secondary-background-highlight;
}

.btn-default:hover {
    color: $color-button-secondary-font-highlight;
    background-color: $color-button-secondary-background-highlight;
    border-color: $color-button-secondary-background-highlight-shade-1;
}

.btn-default:focus {
    color: $color-button-secondary-font-highlight;
    background-color: $color-button-secondary-background-highlight;
    border-color: $color-button-secondary-background-highlight-shade-2;
}

.btn-default:active,
.btn-default:active:focus,
.btn-default:active:hover {
    color: $color-button-secondary-font-highlight;
    background-color: $color-button-secondary-background-highlight-shade-1;
    border-color: $color-button-secondary-background-highlight-shade-2;
}

.btn-disabled {
    font-weight: $typography-main-weight;
    opacity: .65;
    box-shadow: none;
    cursor: not-allowed;
}

.btn-default.btn-disabled:hover,
.btn-default.btn-disabled:focus {
    background-color: #fff;
    border-color: #ccc;
    box-shadow: none;
}

.btn-link {
    color: inherit;
}

.btn-link:active,
.btn-link:focus,
.btn-link:hover {
    color: $color-highlight-primary;
    text-decoration: none;
}

.btn-px {
    font-size: $typography-main-size-px;
}

.btn.btn-lg,
.btn.btn-lg:link {
    padding: ($typography-main-lineHeight-px / 3 * 2 - $button-border-width) $typography-main-lineHeight-px;
    margin: ($typography-main-lineHeight-px / 3 * 2) 0 0;
}

@media screen and (min-width: 1200px) {
    .btn {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
        padding: ($typography-main-lineHeight-large-px / 3 - 1px) ($typography-main-lineHeight-half-max-large-px + 2px);
        margin: ($typography-main-lineHeight-large-px / 3) 0 0;
    }
    .yellowBtn,
    .btn-xcard-main {
        padding: ($typography-main-lineHeight-large-px / 3 - $button-border-width) ($typography-main-lineHeight-half-max-large-px + 2px);
    }

    .btn-alt,
    .btn-top,
    .btn-toolbar-alt .btn {
        margin: 0 0 ($typography-main-lineHeight-large-px / 3);
    }

    .btn-default {
        padding: ($typography-main-lineHeight-large-px / 3 - $button-secondary-border-width) ($typography-main-lineHeight-half-max-large-px + 2px);
    }

    .btn-px {
        font-size: $typography-main-size-large-px;
    }

    .btn.btn-lg,
    .btn.btn-lg:link {
        padding: ($typography-main-lineHeight-large-px / 3 * 2 - $button-border-width) $typography-main-lineHeight-large-px;
        margin: ($typography-main-lineHeight-large-px / 3 * 2) 0 0;
    }
}

.btn.btn-sm,
.btn.btn-sm:link,
.btn.btn-xs,
.btn.btn-xs:link {
    border-width: 1px !important;
    font-size: $typography-xs-size;
    font-weight: $typography-main-weight;
    line-height: $typography-main-lineHeight-px - 2px;
    padding: 4px 5px 3px;
    margin-top: $typography-main-lineHeight-px - 7px;
    min-width: 0;
}

.btn-toolbar-alt .btn.btn-sm,
.btn-toolbar-alt .btn.btn-sm:link,
.btn-toolbar-alt .btn.btn-xs,
.btn-toolbar-alt .btn.btn-xs:link,
.btn.btn-sm.btn-alt,
.btn.btn-sm.btn-alt:link,
.btn.btn-xs.btn-alt,
.btn.btn-xs.btn-alt:link {
    margin-bottom: $typography-main-lineHeight-px - 7px;
    margin-top: 0;
}

.btn.btn-sm,
.btn.btn-sm:link {
    font-size: $typography-sm-size;
}

.btn.btn-xs-px,
.btn.btn-xs-px:link {
    font-size: $typography-xs-size-px;
}

.btn.btn-sm-px,
.btn.btn-sm-px:link {
    font-size: $typography-sm-size-px;
}

@media screen and (min-width: 1200px) {
    .btn.btn-sm,
    .btn.btn-sm:link,
    .btn.btn-xs,
    .btn.btn-xs:link {
        font-size: $typography-xs-size-large;
        margin-top: $typography-main-lineHeight-large-px - 7px;
        line-height: $typography-main-lineHeight-large-px - 2px;
    }

    .btn-toolbar-alt .btn.btn-sm,
    .btn-toolbar-alt .btn.btn-sm:link,
    .btn-toolbar-alt .btn.btn-xs,
    .btn-toolbar-alt .btn.btn-xs:link,
    .btn.btn-sm.btn-alt,
    .btn.btn-sm.btn-alt:link,
    .btn.btn-xs.btn-alt,
    .btn.btn-xs.btn-alt:link {
        margin-bottom: $typography-main-lineHeight-large-px - 7px;
        margin-top: 0;
    }

    .btn.btn-sm,
    .btn.btn-sm:link {
        font-size: $typography-sm-size-large;
    }

    .btn.btn-xs-px,
    .btn.btn-xs-px:link {
        font-size: $typography-xs-size-large-px;
    }

    .btn.btn-sm-px,
    .btn.btn-sm-px:link {
        font-size: $typography-sm-size-large-px;
    }
}

.btn.active.focus,
.btn.active:focus,
.btn.focus,
.btn:active.focus,
.btn:active:focus,
.btn:focus {
    outline: 0;
}

.row-btns .btn {
    margin-right: $typography-main-lineHeight-px / 3;
    margin-bottom: 0;
}

.row-btns .btn:last-child {
    margin-right: 0;
}

input::placeholder {
    color: $color-form-placeholder;
}

.has-error .form-control {
    color: $color-message-error-dark;
}

.checkbox-pretty {
    display:flex;
    flex-flow: row nowrap;

    &.has-error{
        .checkbox-pretty-btn {
            background: $color-message-error-light none;
            border: 2px solid $color-message-error-dark !important;
        }

        .checkbox-pretty-label {
            color: $color-message-error-dark;
        }
    }
}

.checkbox-pretty input[type=checkbox] {
    width: 1px;
    height: 0;
    visibility: hidden;
}

.checkbox-container{
    flex-basis: 8%;
    height: 20px;

    .checkbox-pretty-btn {
        background: url('#{$image-path}/checkbox.png') no-repeat;
        margin: 4px 10px 0 0;
        height: 20px;
        width: 20px;
    }
}

.checkbox-pretty-btn.active {
    background-position: 0 -20px;
}

.checkbox-pretty-label {
    flex-basis: 92%;
    font-size: $typography-main-size;
    font-style: normal;
    font-weight: $typography-main-weight;
    line-height: $typography-main-lineHeight-rel;
    transition: text-shadow 0.4s ease-in-out;
}

.checkbox-pretty-label a {
    border-bottom: 1px dotted $color-body-font;
}

.checkbox-pretty-label a:hover,
.checkbox-pretty-label a:focus,
.checkbox-pretty-label a:active {
    color: $color-highlight-primary;
    border-color: $color-highlight-primary;
}

@media screen and (min-width: 1200px) {
    .checkbox-pretty-label {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
    }
}

.radio-pretty-btn {
    display: none;
    margin-top: 1px;
    width: 20px;
    height: 20px;
    border: 2px solid $color-form-border;
    float: left;
    margin-right: $typography-main-lineHeight-half-max-px;
    border-radius: 10px;
}

.radio-pretty-btn .radio-pretty-btn-inner {
    display: none;
    width: 12px;
    height: 12px;
    margin: 2px 0 0 2px;
    border-radius: 6px;
    background: $color-body-font;
}

@media screen and (min-width: 1200px) {
    .radio-pretty-btn {
        margin-top: 0;
        width: 24px;
        height: 24px;
        border-radius: 12px;
        margin-right: $typography-main-lineHeight-half-max-large-px;
    }

    .radio-pretty-btn .radio-pretty-btn-inner {
        display: none;
        width: 16px;
        height: 16px;
        margin: 2px 0 0 2px;
        border-radius: 8px;
        background: $color-body-font;
    }
}

.radio-pretty.prettified {
    padding-left: 0;
}

.radio-pretty.prettified input[type="radio"] {
    display: none;
}

.radio-pretty.prettified .radio-pretty-btn {
    display: inline-block;
}

.radio-pretty.prettified.checked .radio-pretty-btn-inner {
    display: inline-block;
}

.radio-pretty.prettified .radio-pretty-label {
    display: inline-block;
}

.widget-with-validation-feedback-wrap .feedback-area {
    float: right;
    width: 25px;
    height: $typography-main-lineHeight-px + $typography-main-lineHeight-half-min-px + 4px + 1px + TYPO_MAIN_LINE_HEIGHT_HALF_MIN_PX - 4px;
}

.widget-with-validation-feedback-wrap .widget-area {
    overflow: hidden;
}

.validation-feedback {
    float: right;
    font-size: 16px;
    line-height: $typography-main-lineHeight-px + $typography-main-lineHeight-half-min-px + 4px + 1px + $typography-main-lineHeight-half-min-px - 4px - 2px;
}

@media screen and (min-width: 1200px) {
    .widget-with-validation-feedback-wrap .feedback-area {
        height: $typography-main-lineHeight-large-px + $typography-main-lineHeight-half-min-large-px + 4px + $typography-main-lineHeight-half-min-large-px - 4px;
    }

    .validation-feedback {
        float: right;
        font-size: 16px;
        line-height: $typography-main-lineHeight-large-px + $typography-main-lineHeight-half-min-large-px + 4px + $typography-main-lineHeight-half-min-large-px - 4px - 2px;
    }
}

.btn-with-validation-feedback {
    margin-right: 25px;
}

.validation-feedback.shown-by-hover:hover {
    cursor: help;
}

.validation-feedback-success {
    color: $color-message-success-icon;
    transition: opacity 0.5s ease-in-out;
}

.validation-feedback-warning {
    color: $color-message-warning-icon;
}

.validation-feedback-error {
    color: $color-message-error-icon;
}

.validation-feedback-help {
    color: $color-message-help-icon;
}

.alert-validation-feedback {
    transition: opacity 0.5s ease-in-out;
}

.tooltip-inner {
    min-width: 200px;
    max-width: 200px;
    text-align: left;
}

.tooltip-validation-feedback hr {
    margin: 3px 0;
    border-top: 1px solid #999;
    border-bottom: 0;
    border-right: 0;
    border-left: 0;
}

.tooltip-validation-feedback.right {
    margin-top: -2px;
}

.tooltip-success .tooltip-inner {
    color: #000;
    background-color: $color-message-success-light;
}

.tooltip-success.top .tooltip-arrow {
    border-top-color: $color-message-success-light;
}

.tooltip-success.right .tooltip-arrow {
    border-right-color: $color-message-success-light;
}

.tooltip-success.bottom .tooltip-arrow {
    border-bottom-color: $color-message-success-light;
}

.tooltip-success.left .tooltip-arrow {
    border-left-color: $color-message-success-light;
}

.tooltip-warning .tooltip-inner {
    color: #000;
    background-color: $color-message-warning-light;
}

.tooltip-warning.top .tooltip-arrow {
    border-top-color: $color-message-warning-light;
}

.tooltip-warning.right .tooltip-arrow {
    border-right-color: $color-message-warning-light;
}

.tooltip-warning.bottom .tooltip-arrow {
    border-bottom-color: $color-message-warning-light;
}

.tooltip-warning.left .tooltip-arrow {
    border-left-color: $color-message-warning-light;
}

.tooltip-error .tooltip-inner {
    color: #000;
    background-color: $color-message-error-light;
}

.tooltip-error.top .tooltip-arrow {
    border-top-color: $color-message-error-light;
}

.tooltip-error.right .tooltip-arrow {
    border-right-color: $color-message-error-light;
}

.tooltip-error.bottom .tooltip-arrow {
    border-bottom-color: $color-message-error-light;
}

.tooltip-error.left .tooltip-arrow {
    border-left-color: $color-message-error-light;
}

.tooltip-help .tooltip-inner {
    color: #000;
    background-color: $color-message-help-light;
}

.tooltip-help.top .tooltip-arrow {
    border-top-color: $color-message-help-light;
}

.tooltip-help.right .tooltip-arrow {
    border-right-color: $color-message-help-light;
}

.tooltip-help.bottom .tooltip-arrow {
    border-bottom-color: $color-message-help-light;
}

.tooltip-help.left .tooltip-arrow {
    border-left-color: $color-message-help-light;
}

.verifymobile-verificationCode {
    max-width: 90px;
}

img.pre-input-icon {
    box-sizing: content-box;
    height: $typography-main-lineHeight-px;
    width: auto;
    padding: ($typography-main-lineHeight-half-min-px / 2) 0;
    margin: ($typography-main-lineHeight-half-min-px / 2) 0;
}

div.pre-input-icon-wrap {
    float: left;
}

div.input-with-icon-wrap {
    margin: 0 0 0 ($typography-main-lineHeight-px + $typography-main-lineHeight-half-max-px);
}

@media screen and (min-width: 1200px) {
    img.pre-input-icon {
        box-sizing: content-box;
        height: $typography-main-lineHeight-large-px;
        width: auto;
        padding: ($typography-main-lineHeight-half-min-large-px / 2) 0;
        margin: ($typography-main-lineHeight-half-min-large-px / 2) 0;
    }

    div.input-with-icon-wrap {
        margin: 0 0 0 (TYPO_MAIN_LINE_HEIGHT_PX + TYPO_MAIN_LINE_HEIGHT_HALF_MAX_PX);
    }
}

/*
 * 1.5.1 jQuery-UI Datepicker
 */

#ui-datepicker-div {
    font-size: 10px; /* tbd */
}

.ui-datepicker-prev,
.ui-datepicker-next {
    text-indent: -9999px;
}

/* -----------------------------------------------
 * 1.6 Tables
 * -------------------------------------------- */

.table {
    font-size: $typography-sm-size;
    margin-bottom: $typography-main-lineHeight-px;
}

.table,
.table-content .search-area,
.table-content .table,
.table-content .page-selector,
.table-content form {
    background: #fff;
}

.table thead {
    background: #eee;
}

.table-responsive {
    margin-bottom: $typography-main-lineHeight-px;
}

.table-responsive > .table {
    margin-bottom: 0;
}

.table-excel-like {
    border-color: #c0c0c0;
}

.table-excel-like > tbody > tr > td,
.table-excel-like > tbody > tr > th,
.table-excel-like > tfoot > tr > td,
.table-excel-like > tfoot > tr > th,
.table-excel-like > thead > tr > td,
.table-excel-like > thead > tr > th {
    padding: ($typography-main-lineHeight-px / 7 + 1px) ($typography-main-lineHeight-px / 7 * 3) ($typography-main-lineHeight-px / 7 - 1px) !important;
    font-family: Helvetica, Arial, sans-serif;
    border-color: #c0c0c0;
    vertical-align: top;
}

.table-excel-like thead {
    background: #fff;
}

.table-excel-like > thead > tr > td,
.table-excel-like > thead > tr > th {
    border-bottom-width: 1px !important;
}

.table a {
    font-weight: 400;
}

.alert table {
    color: #333;
}

.alert .table-responsive {
    margin-bottom: 0;
}

td.monetary-amount {
    white-space: pre;
}

tr.has-error {
    background: $color-message-error-light;
}

.table-striped > tbody > tr.has-error:nth-of-type(odd) {
    background: $color-message-error-medium;
}

.table-scrolltable-wrapper-lg {
    max-height: 500px;
}

.table > tbody > tr > td,
.table > tbody > tr > th,
.table > tfoot > tr > td,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > thead > tr > th,
.table.table-condensed > tbody > tr > td,
.table.table-condensed > tbody > tr > th,
.table.table-condensed > tfoot > tr > td,
.table.table-condensed > tfoot > tr > th,
.table.table-condensed > thead > tr > td,
.table.table-condensed > thead > tr > th {
    padding: 8px 8px 5px;
    line-height: $typography-sm-lineHeight-small-rel;
    vertical-align: middle;
    border-top: 1px solid #ddd;
}

.table-bordered > thead > tr > td,
.table-bordered > thead > tr > th {
    border-bottom-width: 1px;
}

.table-bordered > thead > tr:last-child > td,
.table-bordered > thead > tr:last-child > th {
    border-bottom-width: 2px;
}

.table tr.linked-row:hover {
    cursor: pointer;
    color: #000;
    background: $color-highlight-primary-light-1;
}

@media screen and (max-width: 991px) {
    .table-responsive {
        width: 100%;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        border: 1px solid #ddd;
    }

    .table-responsive > .table > thead > tr > th,
    .table-responsive > .table > tbody > tr > th,
    .table-responsive > .table > tfoot > tr > th,
    .table-responsive > .table > thead > tr > td,
    .table-responsive > .table > tbody > tr > td,
    .table-responsive > .table > tfoot > tr > td {
        white-space: nowrap;
    }

    .table-responsive > .table-bordered {
        border: 0;
    }

    .table-responsive > .table-bordered > thead > tr > th:first-child,
    .table-responsive > .table-bordered > tbody > tr > th:first-child,
    .table-responsive > .table-bordered > tfoot > tr > th:first-child,
    .table-responsive > .table-bordered > thead > tr > td:first-child,
    .table-responsive > .table-bordered > tbody > tr > td:first-child,
    .table-responsive > .table-bordered > tfoot > tr > td:first-child {
        border-left: 0;
    }

    .table-responsive > .table-bordered > thead > tr > th:last-child,
    .table-responsive > .table-bordered > tbody > tr > th:last-child,
    .table-responsive > .table-bordered > tfoot > tr > th:last-child,
    .table-responsive > .table-bordered > thead > tr > td:last-child,
    .table-responsive > .table-bordered > tbody > tr > td:last-child,
    .table-responsive > .table-bordered > tfoot > tr > td:last-child {
        border-right: 0;
    }

    .table-responsive > .table-bordered > tbody > tr:last-child > th,
    .table-responsive > .table-bordered > tfoot > tr:last-child > th,
    .table-responsive > .table-bordered > tbody > tr:last-child > td,
    .table-responsive > .table-bordered > tfoot > tr:last-child > td {
        border-bottom: 0;
    }
}

.table-info-area {
    overflow: hidden;
}

.table-info-area .module-left {
    overflow: hidden;
}

.table-info-area .module-right {
    overflow: hidden;
}

@media screen and (min-width: 992px) {
    .table-info-area .module-left {
        position: relative;
        float: left;
        width: 250px;
    }

    .table-info-area .module-right {
        position: relative;
        float: right;
        width: 250px;
    }

    .table-info-area .pagination-container {
        margin: 0 250px;
    }
}

@media screen and (min-width: 1200px) {
    .table-info-area .module-left {
        width: 300px;
    }

    .table-info-area .module-right {
        width: 300px;
    }

    .table-info-area .pagination-container {
        margin: 0 300px;
    }
}

@media screen and (min-width: 1200px) {
    .table-responsive {
        margin-bottom: $typography-main-lineHeight-large-px;
    }

    .table {
        font-size: $typography-sm-size-large;
        margin-bottom: $typography-main-lineHeight-large-px;
    }

    .table-excel-like > tbody > tr > td,
    .table-excel-like > tbody > tr > th,
    .table-excel-like > tfoot > tr > td,
    .table-excel-like > tfoot > tr > th,
    .table-excel-like > thead > tr > td,
    .table-excel-like > thead > tr > th {
        padding: ($typography-main-lineHeight-large-px / 8 + 1px) ($typography-main-lineHeight-large-px / 8 * 3) ($typography-main-lineHeight-large-px / 8 - 1px) !important;
    }

    .table > tbody > tr > td,
    .table > tbody > tr > th,
    .table > tfoot > tr > td,
    .table > tfoot > tr > th,
    .table > thead > tr > td,
    .table > thead > tr > th,
    .table.table-condensed > tbody > tr > td,
    .table.table-condensed > tbody > tr > th,
    .table.table-condensed > tfoot > tr > td,
    .table.table-condensed > tfoot > tr > th,
    .table.table-condensed > thead > tr > td,
    .table.table-condensed > thead > tr > th {
        line-height: $typography-sm-lineHeight-small-large-rel;
    }
}

table.table-flip-md td span.flipped-label,
table.table-flip-sm td span.flipped-label,
table.table-flip-xs td span.flipped-label,
table.table-flip-xxs td span.flipped-label {
    display: none;
    color: inherit;
}

.table-row-noentries {
    font-style: italic;
    text-align: center;
}

.transaction-amount {
    text-align: right;
}

.btn-pagination,
.pagination-ellipsis {
    margin-left: 3px;
    margin-right: 3px;
}

@media screen and (max-width: 599px) {
    table.table-flip-xxs,
    table.table-flip-xxs thead,
    table.table-flip-xxs tbody,
    table.table-flip-xxs th,
    table.table-flip-xxs td,
    table.table-flip-xxs tr {
        display: block;
    }

    table.table-flip-xxs thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    table.table-flip-xxs tr {
        border: 1px solid #ccc;
    }

    table.table-flip-xxs td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
    }

    table.table-flip-xxs td span.flipped-label {
        display: block;
        width: 45%;
        float: left;
    }

    table.table-flip-xxs td span.value {
        display: block;
        padding-left: 50%;
        font-weight: 700;
    }

    table.table-flip-xxs.collapsed td span.flipped-label {
        width: 100%;
        float: none;
    }

    table.table-flip-xxs.collapsed td span.value {
        padding-left: 0;
    }

    table.table-flip-xxs .transaction-amount {
        text-align: left;
    }

    table.table-flip-xxs.table-flip-hide-empty td.td-empty {
        display: none;
    }
}

@media screen and (max-width: 767px) {
    table.table-flip-xs,
    table.table-flip-xs thead,
    table.table-flip-xs tbody,
    table.table-flip-xs th,
    table.table-flip-xs td,
    table.table-flip-xs tr {
        display: block;
    }

    table.table-flip-xs thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    table.table-flip-xs tr {
        border: 1px solid #ccc;
    }

    table.table-flip-xs td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
    }

    table.table-flip-xs td span.flipped-label {
        display: block;
        width: 45%;
        float: left;
    }

    table.table-flip-xs td span.value {
        display: block;
        padding-left: 50%;
        font-weight: 700;
    }

    table.table-flip-xs.collapsed td span.flipped-label {
        width: 100%;
        float: none;
    }

    table.table-flip-xs.collapsed td span.value {
        padding-left: 0;
    }

    table.table-flip-xs .transaction-amount {
        text-align: left;
    }

    table.table-flip-xs.table-flip-hide-empty td.td-empty {
        display: none;
    }
}

@media screen and (max-width: 991px) {
    table.table-flip-sm,
    table.table-flip-sm thead,
    table.table-flip-sm tbody,
    table.table-flip-sm th,
    table.table-flip-sm td,
    table.table-flip-sm tr {
        display: block;
    }

    table.table-flip-sm thead tr {
        position: absolute;
        top: -9999px;
        left: -9999px;
    }

    table.table-flip-sm tr {
        border: 1px solid #ccc;
    }

    table.table-flip-sm td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
    }

    table.table-flip-sm td span.flipped-label {
        display: block;
        width: 45%;
        float: left;
    }

    table.table-flip-sm td span.value {
        display: block;
        padding-left: 50%;
        font-weight: 700;
    }

    table.table-flip-sm.collapsed td span.flipped-label {
        width: 100%;
        float: none;
    }

    table.table-flip-sm.collapsed td span.value {
        padding-left: 0;
    }

    table.table-flip-sm .transaction-amount {
        text-align: left;
    }

    table.table-flip-sm.table-flip-hide-empty td.td-empty {
        display: none;
    }
}

@media screen and (max-width: 1199px) {
    table.table-flip-md,
    table.table-flip-md thead,
    table.table-flip-md tbody,
    table.table-flip-md th,
    table.table-flip-md td,
    table.table-flip-md tr {
        display: block;
    }

    table.table-flip-md thead tr {
        position: absolute;
        top: -99999px;
        left: -99999px;
    }

    table.table-flip-md tr {
        border: 1px solid #ccc;
    }

    table.table-flip-md td {
        border: none;
        border-bottom: 1px solid #eee;
        position: relative;
        overflow: hidden;
        vertical-align: middle;
    }

    table.table-flip-md td span.flipped-label {
        display: block;
        width: 45%;
        float: left;
    }

    table.table-flip-md td span.value {
        display: block;
        padding-left: 50%;
        font-weight: 700;
    }

    table.table-flip-md.collapsed td span.flipped-label {
        width: 100%;
        float: none;
    }

    table.table-flip-md.collapsed td span.value {
        padding-left: 0;
    }

    table.table-flip-md .transaction-amount {
        text-align: left;
    }

    table.table-flip-md.table-flip-hide-empty td.td-empty {
        display: none;
    }
}

/*
 * 1.6.1 "Scrollable Table"
 *
 * CLA - formerly separate file
 */

.table-scrolltable-wrapper {
    margin: $typography-main-lineHeight-half-min-px 0 $typography-main-lineHeight-half-max-px;
    max-height: 300px;
    overflow-y: scroll;
    position: relative;
    border: none;
}

td.selected,
.ReactTable.-striped .rt-tr.selected,
.ReactTable.-striped .rt-tr.selected.-odd {
    color: #000;
    background: $color-highlight-primary;
}

.ReactTable.-striped {
    .rt-td a:hover {
        color: $color-highlight-primary-light-1;
    }

    .rt-tr.has-error.-odd {
        background-color: $color-message-error-medium;
        color: black;
    }

    .rt-tr.has-error.-even {
        background-color: $color-message-error-light;
        color: black;
    }
}

.ReactTable .rt-tr.linked-row:hover {
    cursor: pointer;
    background: $color-highlight-primary-light-1;
}

.ReactTable.linked-table .rt-tbody .rt-tr-group:hover {
  cursor: pointer;
  color: #000;
  background: $color-highlight-primary-light-1;
}

.tablesorter {
    text-align: left;
    border-collapse: separate;
}

.tablesorter .header,
.tablesorter .tablesorter-header {
    cursor: pointer;
    background: url('#{$image-path}/sort.gif') no-repeat right 5px center;
}

.tablesorter th.headerSortAsc,
.tablesorter th.tablesorter-headerSortAsc {
    background: url('#{$image-path}/sort_asc.gif') no-repeat right 5px center;
}

.tablesorter th.headerSortDesc,
.tablesorter th.tablesorter-headerSortDesc {
    background: url('#{$image-path}/sort_desc.gif') no-repeat right 5px center;
}

.tablesorter tbody > tr:hover {
    cursor: pointer;
}

.tablesorter tbody > tr:hover > td {
    color: #000;
    background: $color-highlight-primary-light-1;
}

.tablesorter input[type=text] {
    display: block;
    width: 100%;
    height: 26px;
    padding: 3px 6px 2px;
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: #555;
    background: #fff none;
    border: 1px solid #ccc;
    border-radius: $border-radius;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.tablesorter input[type=text]:focus {
    outline: 0;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(170, 170, 170, .6);
}

/* -----------------------------------------------
 * 1.7 Pretty File Widget
 * -------------------------------------------- */

.upload-input {
    position: relative;
}

.upload-input input[type="file"] {
    position: relative;
    z-index: 1;
}

.style-file-input-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    opacity: 0;
}

.document-upload-column .upload-input input[type="file"] {
    height: 34px;
    width: 100%;
    opacity: 0;
    cursor: pointer;
}

.document-upload-column .style-file-input-wrapper {
    opacity: 1;
}

.document-upload-column .style-file-input-wrapper input[type="text"] {
    display: inline-block;
    width: 60%;
    margin-bottom: 0;
}

/*
 * 1.7.1 Ajax File Widget
 */

.btn-fileinput {
    position: relative;
    overflow: hidden;
    display: inline-block;
}

.btn-fileinput input {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    opacity: 0;
    font-size: 200px;
    direction: ltr;
    cursor: pointer;
}

.upload-feedback-wrap .filename {
    font-weight: 400;
}

/* -----------------------------------------------
 * 1.8 Date Range Picker
 * -------------------------------------------- */

.daterangepicker-wrap {
    display: inline-block;
    padding: 0;
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    color: $color-body-font;
    cursor: pointer;
    touch-action: manipulation;
    transition: color .3s ease, border-color .3s ease, background-color .3s ease, text-shadow .3s ease;
}

.daterangepicker-wrap .caret {
    color: $color-highlight-primary;
}

.daterangepicker-wrap:hover,
.daterangepicker-wrap:focus,
.daterangepicker-wrap:active {
    color: $color-highlight-primary;
}

.daterangepicker {
    position: absolute;
    background: #fff;
    top: 100px;
    left: 20px;
    padding: 4px;
    margin-top: 1px;
    border: $title-secondary-border-width solid $color-gray-shade-1;
    border-radius: $border-radius;
}

.daterangepicker.dropdown-menu {
    max-width: none;
    z-index: 3000;
}

.daterangepicker.opensleft .ranges,
.daterangepicker.opensleft .calendar {
    float: left;
    margin: 4px;
}

.daterangepicker.opensright .ranges,
.daterangepicker.opensright .calendar {
    float: right;
    margin: 4px;
}

.daterangepicker .ranges {
    width: 180px;
    text-align: left;
}

.daterangepicker .ranges .range_inputs > div {
    float: left;
    width: 47%;
}

.daterangepicker .ranges .range_inputs > div:nth-of-type(2) {
    float: right;
}

.daterangepicker .btn.btn-xs,
.daterangepicker .btn.btn-xs:link {
    margin-top: $typography-main-lineHeight-half-min-px;
}

.daterangepicker .calendar {
    display: none;
    max-width: 250px;
}

.daterangepicker.show-calendar .calendar {
    display: block;
}

.daterangepicker .calendar.single .calendar-date {
    border: none;
}

.daterangepicker .calendar th,
.daterangepicker .calendar td {
    white-space: nowrap;
    text-align: center;
}

.daterangepicker .daterangepicker_start_input label,
.daterangepicker .daterangepicker_end_input label {
    color: #333;
    font-size: 11px;
    margin-bottom: 2px;
    text-transform: uppercase;
    text-shadow: 1px 1px 0 #fff;
}

.daterangepicker .ranges input {
    font-size: 11px;
    cursor: pointer;
    height: 26px;
    padding: 6px;
}

.daterangepicker .input-mini {
    width: 100%;
    background: #fff none;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075);
    transition: border-color ease-in-out .15s, box-shadow ease-in-out .15s;
}

.daterangepicker .ranges ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

.daterangepicker .ranges li {
    font-size: 13px;
    font-weight: 700;
    background: #f5f5f5;
    border: 1px solid #f5f5f5;
    color: $color-body-font;
    padding: 3px 12px;
    margin-bottom: 8px;
    border-radius: 4px;
    cursor: pointer;
}

.daterangepicker .ranges li.active,
.daterangepicker .ranges li:hover {
    background: $color-button-background;
    border: 1px solid $color-button-background;
    color: $color-button-font;
}

.daterangepicker .calendar-date {
    border: 1px solid #ddd;
    padding: 4px;
    border-radius: 4px;
    background: #fff;
}

.daterangepicker .calendar-time {
    text-align: center;
    margin: 8px auto 0 auto;
    line-height: 30px;
}

.daterangepicker.opensleft:before {
    position: absolute;
    top: -7px;
    right: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    content: '';
}

.daterangepicker.opensleft:after {
    position: absolute;
    top: -6px;
    right: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
}

.daterangepicker.opensright:before {
    position: absolute;
    top: -7px;
    left: 9px;
    display: inline-block;
    border-right: 7px solid transparent;
    border-bottom: 7px solid rgba(0, 0, 0, 0.2);
    border-left: 7px solid transparent;
    content: '';
}

.daterangepicker.opensright:after {
    position: absolute;
    top: -6px;
    left: 10px;
    display: inline-block;
    border-right: 6px solid transparent;
    border-bottom: 6px solid #fff;
    border-left: 6px solid transparent;
    content: '';
}

.daterangepicker table {
    width: 100%;
    margin: 0;
}

.daterangepicker td,
.daterangepicker th {
    text-align: center;
    width: 20px;
    height: 20px;
    border-radius: 4px;
    cursor: pointer;
    white-space: nowrap;
}

.daterangepicker td.off {
    color: #999;
}

.daterangepicker td.disabled {
    color: #999;
}

.daterangepicker td.available:hover,
.daterangepicker th.available:hover {
    background: #eeeeee;
}

.daterangepicker td.in-range {
    background: #fff1cc;
    border-radius: 0;
}

.daterangepicker td.active,
.daterangepicker td.active:hover {
    background: $color-highlight-primary linear-gradient(top, $color-highlight-primary, $color-highlight-primary-shade-1);
    border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.25);
    color: #fff;
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25);
}

.daterangepicker td.week,
.daterangepicker th.week {
    font-size: 80%;
    color: #ccc;
}

.daterangepicker select.monthselect,
.daterangepicker select.yearselect {
    font-size: 12px;
    padding: 1px;
    height: auto;
    margin: 0;
    cursor: default;
}

.daterangepicker select.monthselect {
    margin-right: 2%;
    width: 56%;
}

.daterangepicker select.yearselect {
    width: 40%;
}

.daterangepicker select.hourselect,
.daterangepicker select.minuteselect,
.daterangepicker select.ampmselect {
    width: 60px;
    margin-bottom: 0;
}

.daterangepicker_start_input {
    float: left;
}

.daterangepicker th.month {
    width: auto;
}

@media screen and (min-width: 1200px) {
    .daterangepicker-wrap {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
    }
}

/* -----------------------------------------------
 * 1.9 Card Column
 * -------------------------------------------- */

.col-card-image {
    text-align: center;
    width: 100%;
}

@media screen and (min-width: 540px) {
    .col-card-image-xxs {
        float: left;
        width: 261px;
    }

    .col-card-image-sibling-xxs {
        margin-left: 261px + $typography-main-lineHeight-px;
    }
}

@media screen and (min-width: 600px) {
    .col-card-image-sibling-xxs {
        margin-left: 261px + $typography-main-lineHeight-px * 2;
    }
}

@media screen and (min-width: 650px) {
    .col-card-image-xs {
        float: left;
        width: 261px;
    }

    .col-card-image-sibling-xs {
        margin-left: 261px + $typography-main-lineHeight-px;
    }
}

@media screen and (min-width: 768px) {
    .col-card-image {
        float: left;
        width: 261px;
    }

    .col-card-image-lg {
        text-align: center;
        width: 100%;
    }

    .col-card-image-sibling {
        margin-left: 261px + $typography-main-lineHeight-px * 2;
    }

    .col-card-image-sibling-lg {
        margin-left: 0;
    }
}

@media screen and (min-width: 1200px) {
    .col-card-image-sibling,
    .col-card-image-sibling-lg {
        margin-left: 261px + $typography-main-lineHeight-large-px * 2;
    }

    .col-card-image-lg {
        float: left;
        width: 261px;
    }
}

@media screen and (max-width: 767px) {
    .order-image {
        display:none;
    }
}

.cardtip {
    position: absolute;
    z-index: 1049;
    display: block;
    font-family: $typography-body-family;
    font-size: $typography-xs-size;
    font-style: normal;
    font-weight: normal;
    line-height: 1.5;
    text-align: left;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    opacity: .9;
    max-width: 250px;
}

.cardtip.top {
    padding: 5px 0;
    margin-top: -3px;
}

.cardtip.right {
    padding: 0 5px;
    margin-left: 3px;
}

.cardtip.bottom {
    padding: 5px 0;
    margin-top: 3px;
}

.cardtip.left {
    padding: 0 5px;
    margin-left: -3px;
}

.cardtip-inner {
    padding: 3px 8px;
    color: $color-tooltip-default-font;
    text-align: center;
    background-color: $color-tooltip-default-background;
    border-radius: 4px;
}

.cardtip-arrow {
    position: absolute;
    width: 0;
    height: 0;
    border: solid transparent;
}

.cardtip.top .cardtip-arrow {
    bottom: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 5px 5px 0;
    border-top-color: $color-tooltip-default-background;
}

.cardtip.top-left .cardtip-arrow {
    right: 5px;
    bottom: 0;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: $color-tooltip-default-background;
}

.cardtip.top-right .cardtip-arrow {
    bottom: 0;
    left: 5px;
    margin-bottom: -5px;
    border-width: 5px 5px 0;
    border-top-color: $color-tooltip-default-background;
}

.cardtip.right .cardtip-arrow {
    top: 50%;
    left: 0;
    margin-top: -5px;
    border-width: 5px 5px 5px 0;
    border-right-color: $color-tooltip-default-background;
}

.cardtip.left .cardtip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-width: 5px 0 5px 5px;
    border-left-color: $color-tooltip-default-background;
}

.cardtip.bottom .cardtip-arrow {
    top: 0;
    left: 50%;
    margin-left: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: $color-tooltip-default-background;
}

.cardtip.bottom-left .cardtip-arrow {
    top: 0;
    right: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: $color-tooltip-default-background;
}

.cardtip.bottom-right .cardtip-arrow {
    top: 0;
    left: 5px;
    margin-top: -5px;
    border-width: 0 5px 5px;
    border-bottom-color: $color-tooltip-default-background;
}

#ninetip {
    left: 5px;
    top: 164px;
}

#ninetip .cardtip-arrow {
    left: 10%;
}

#fourtip {
    right: 5px;
    bottom: 109px;
}

#fourtip .cardtip-arrow {
    left: 85%;
}

@media screen and (min-width: 1200px) {
    .cardtip {
        font-size: $typography-xs-size-large;
    }

    .cardtip-inner {
        padding: 4px 10px 3px;
    }
}

.validThruCardField {
    position: absolute;
    left: 46%;
    top: 71%;
    font-family: 'OCR-A W00 Regular', monospace;
    font-size: 12px;
    text-transform: uppercase;
    color: #fff;
    text-shadow: 1px 1px #000;
}

.nameOnCardField {
    position: absolute;
    left: 10%;
    top: 82%;
    width: 170px;
    height: 20px;
    overflow: hidden;
    font-family: 'OCR-A W00 Regular', monospace;
    text-transform: uppercase;
    font-size: 13px;
    color: #fff;
    text-shadow: 1px 1px #000;
    text-align: left;
}

.marker-outer {
    position: absolute;
    height: $typography-main-lineHeight-half-max-px * 2 + 4px;
    width: $typography-main-lineHeight-half-max-px * 2 + 4px;
    border-radius: $typography-main-lineHeight-half-max-px + 2px;
    background-color: $color-body-background;
}

.marker-inner {
    position: absolute;
    top: 2px;
    left: 2px;
    height: $typography-main-lineHeight-half-max-px * 2;
    width: $typography-main-lineHeight-half-max-px * 2;
    line-height: $typography-main-lineHeight-half-max-px * 2;
    font-size: $typography-main-size-px;
    font-weight: 700;
    border-radius: $typography-main-lineHeight-half-max-px;
    background-color: $color-header-background;
    color: #fff;
}

#marker-4-digit {
    top: 48%;
    right: ($typography-main-lineHeight-half-max-px + 2px) * -1;
}

#marker-9-digit {
    bottom: 2px;
    left: 16%;
}

@media screen and (min-width: 1200px) {
    .marker-outer {
        height: $typography-main-lineHeight-half-max-large-px * 2 + 4px;
        width: $typography-main-lineHeight-half-max-large-px * 2 + 4px;
        border-radius: $typography-main-lineHeight-half-max-large-px + 2px;
    }

    .marker-inner {
        height: $typography-main-lineHeight-half-max-large-px * 2;
        width: $typography-main-lineHeight-half-max-large-px * 2;
        line-height: $typography-main-lineHeight-half-max-large-px * 2;
        font-size: $typography-main-size-large-px;
        border-radius: $typography-main-lineHeight-half-max-large-px;
    }

    #marker-4-digit {
        top: 47%;
        right: ($typography-main-lineHeight-half-max-large-px + 2px) * -1;
    }

    #marker-9-digit {
        bottom: 2px;
    }
}

/* -----------------------------------------------
 * II. Header & Footer
 * -------------------------------------------- */

/* -----------------------------------------------
 * 2.1 Header
 * -------------------------------------------- */

div.container-header {
    z-index: 9100;
    height: 50px;
    background: $color-header-background;
    box-shadow: $shadow-header;
    border-bottom: 1px solid $color-header-background-shade-1;
    color: $color-header-font;
    position: relative;
}

html.csstransforms3d div.container-header {
    transform: translate3d(0, 0, 0);
    transition: transform .3s ease-out;
}

div#header-background {
    position: absolute;
    z-index: 9110;
    height: 50px;
    width: 100%;
    background: $color-header-background;
}

div.container-header a,
div.container-header button,
div.container-header input[type=submit] {
    transition: none;
}

div.header-left {
    position: relative;
    z-index: 9120;
    width: 56px;
    float: left;
}

div.header-right {
    position: relative;
    z-index: 9120;
    width: 56px;
    float: right;
}

div.header-center {
    position: relative;
    z-index: 9120;
    margin: 0 56px;
    text-align: center;
    display: none;
}

@media screen and (min-width: 240px) {
    div.header-center {
        display: block;
    }
}

ul.nav-menu.nav-menu-header {
    list-style: none;
    margin: 0;
    padding: 0;
    display: none;
    font-family: $typography-header-nav-family;
}

ul.nav-menu.nav-menu-header li {
    display: inline-block;
}

ul.nav-menu.nav-menu-header li a {
    display: inline-block;
    font-size: $typography-header-nav-size;
    line-height: 60px;
    padding: 0 15px;
    color: $color-header-font;
    font-weight: $typography-header-nav-weight;
}

ul.nav-menu.nav-menu-header li a.active {
    text-shadow: 0 0 2px $color-header-font-highlight-shadow;
    color: $color-highlight-primary;
}

ul.nav-menu.nav-menu-header li a span.bi {
    display: none;
}

ul.nav-menu.nav-menu-header li a:active,
ul.nav-menu.nav-menu-header li a:focus,
html.no-touchevents ul.nav-menu.nav-menu-header li a:hover {
    text-shadow: 0 0 1px $color-highlight-primary, 0 0 2px $color-highlight-primary;
    color: $color-header-mainNav-font-highlight;
    background: $color-header-background-shade-1;
}

div#fixed-header-push {
    display: block;
    width: 100%;
    height: 0;
}

@media screen and (min-width: 360px) {
    div.container-header {
        height: 60px;
    }

    div#header-background {
        height: 60px;
    }

    div.header-left {
        width: 70px;
    }

    div.header-right {
        width: 70px;
    }

    div.header-center {
        margin: 0 70px;
    }
}

@media screen and (min-width: 1024px) {
    div.header-left {
        width: 190px;
    }

    div.header-right {
        width: 190px;
    }

    div.header-center {
        margin: 0 190px;
    }

    ul.nav-menu.nav-menu-fold-out {
        display: none;
    }

    ul.nav-menu.nav-menu-header {
        display: block;
    }
}

@media screen and (min-width: 1200px) {
    div.header-left {
        width: 300px;
    }

    div.header-right {
        width: 300px;
    }

    div.header-center {
        margin: 0 300px;
    }
}

@media screen and (min-height: 360px) {
    div.container-header {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        -webkit-overflow-scrolling: touch;
    }

    div#fixed-header-push {
        height: 60px;
    }

    html.csstransforms3d div.container-header.collapsed {
        transform: translate3d(0, -60px, 0);
    }
}

@media screen and (min-height: 340px) and (min-width: 480px) {
    div.container-header {
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        -webkit-overflow-scrolling: touch;
    }

    div#fixed-header-push {
        height: 60px;
    }

    html.csstransforms3d div.container-header.collapsed {
        transform: translate3d(0, -60px, 0);
    }
}

@media screen and (min-height: 720px) and (min-width: 768px) {
    div.container-header.collapsed {
        height: 60px;
        overflow: visible;
    }

    html.csstransforms3d div.container-header.collapsed {
        transform: translate3d(0, 0, 0);
    }
}

/*
 * 2.1.1 Logo
 */

div.logo-wrap a {
    display: inline-block;
    overflow: hidden;
}

div.logo-wrap-left {
    display: none;
}

img.logo {
    width: auto;
    height: 17px;
    margin: 17px 17px 16px;
}

@media screen and (min-width: 360px) {
    img.logo {
        width: auto;
        height: 20px;
        margin: 20px;
    }
}

@media screen and (min-width: 480px) {
    img.logo {
        width: auto;
        height: 24px;
        margin: 18px;
    }
}

@media screen and (min-width: 1024px) {
    img.logo {
        width: auto;
        height: 30px;
        margin: 15px;
    }

    div.logo-wrap-left {
        display: block;
    }

    div.logo-wrap-center {
        display: none;
    }
}

/*
 * 2.1.2 User Area
 */

div.logged-in-counter {
    display: inline-block;
    float: right;
    text-align: right;
    padding: 0;
    line-height: 20px;
    font-size: 13px;
}

div.logged-in-counter.logged-in-counter-fold-out {
    position: relative;
    z-index: 9120;
    padding: 10px 15px 10px;
    float: none;
    display: block;
    background: $color-header-background-shade-1;
}

div.logged-in-counter.logged-in-counter-header {
    display: none;
    padding: 1px 0 0;
    line-height: 20px;
    font-size: 13px;
}

div.logged-in-counter span.time-left {
    color: #cd8d04;
    font-weight: 700;
}

div.logged-in-counter span.time-left-one-minute {
    color: #ec2208;
}

div#btn-user-menu-wrap {
    display: inline-block;
    float: right;
}

button#btn-user-menu {
    background: transparent;
    border: 0;
    height: 50px;
    padding: 0 15px 0 17px;
    vertical-align: middle;
    color: $color-header-font;
}

button#btn-user-menu i.user-menu-icon {
    display: inline-block;
    font-size: 24px;
    line-height: 1 !important;
    height: 24px;
    width: auto;
    float: right;
    padding: 0;
    margin: -2px 0 0;
}

button#btn-user-menu:active,
button#btn-user-menu:focus,
html.no-touchevents button#btn-user-menu:hover,
button#btn-user-menu.in-use {
    color: $color-header-font-highlight;
    background: $color-header-background-shade-1;
}

@media screen and (min-width: 360px) {
    button#btn-user-menu {
        height: 60px;
        padding: 0 20px 0 23px;
    }

    button#btn-user-menu i.user-menu-icon {
        height: 26px;
        font-size: 26px;
        padding: 0;
        margin: 0;
    }
}

@media screen and (min-width: 480px) {
    div.logged-in-counter.logged-in-counter-fold-out {
        display: inline-block;
    }
}

@media screen and (min-width: 1200px) {
    div.logged-in-counter.logged-in-counter-fold-out br,
    div.logged-in-counter.logged-in-counter-fold-out span.welcome-wrap {
        display: none;
    }

    div.logged-in-counter.logged-in-counter-header {
        display: block;
    }

    button#btn-user-menu {
        padding: 0 15px 0 17px;
    }

    button#btn-user-menu i.user-menu-icon {
        margin: 7px 0 0 13px;
    }

    button#btn-user-menu i.user-menu-icon.cla {
        margin-top: 0;
    }

    div.logged-in-counter.logged-in-counter-header.without-welcome {
        padding-top: 4px;
    }
}

/*
 * 2.1.3 Fold-out menus
 */
button#btn-hamburger {
    background: transparent;
    border: 0;
    height: 50px;
    width: 56px;
    margin: 0;
    padding: 0 14px 0 12px;
    vertical-align: middle;
    color: #f3f3f3;
}

button#btn-hamburger span.icon-bar {
    display: block;
    width: 30px;
    border-radius: 2px;
    height: 4px;
    background-color: $color-header-font;
    margin: 5px 0;
}

button#btn-hamburger:active,
button#btn-hamburger:focus,
html.no-touchevents button#btn-hamburger:hover,
button#btn-hamburger.in-use {
    color: $color-header-button-highlight;
    background: $color-header-background-shade-1;
}

button#btn-hamburger:active span.icon-bar,
button#btn-hamburger:focus span.icon-bar,
html.no-touchevents button#btn-hamburger:hover span.icon-bar,
button#btn-hamburger.in-use span.icon-bar {
    background: $color-header-button-highlight;
    box-shadow: 0 0 2px 1px $color-highlight-primary;
}

div.fold-out-menu {
    font-size: 13px; /* themeable? */
    height: auto;
    width: 100%;
    padding: 0;
    position: absolute;
    z-index: 9101;
    top: -500px;
    background: $color-header-background-shade-1;
    border: 0 solid $color-header-background-shade-1;
}

div.fold-out-menu.open {
    z-index: 9102;
    top: 49px;
}

div.fold-out-menu-right {
    right: 0;
    text-align: right;
}

div.fold-out-menu-left {
    left: 0;
}

html.csstransforms3d div.fold-out-menu,
html.csstransforms3d div.fold-out-menu-left,
html.csstransforms3d div.fold-out-menu-right {
    top: -500px;
    transform: translate3d(0, 0, 0);
}

html.csstransforms3d div.fold-out-menu.open,
html.csstransforms3d div.fold-out-menu-left.open,
html.csstransforms3d div.fold-out-menu-right.open {
    top: -500px;
    transform: translate3d(0, 500px, 0);
}

div.fold-out-menu ul.fold-out-menu-list {
    width: 100%;
    margin: 0;
    padding: 0 0 5px;
    list-style: none;
    text-align: left;
    background: $color-header-background-shade-1;
}

div.fold-out-menu-left ul.fold-out-menu-list {
    padding: 5px 0;
}

ul.fold-out-menu-list li {
    margin: 0;
    padding: 0;
}

ul.fold-out-menu-list li a,
ul.fold-out-menu-list li div.fold-out-menu-item-wrapper {
    line-height: 1;
    display: block;
    padding: 6px 15px 7px;
    color: $color-header-font;
    white-space: nowrap;
}

ul.fold-out-menu-list li div.fold-out-menu-item-wrapper {
    line-height: 1.153846154;
    padding: 4px 15px 5px;
}

ul.fold-out-menu-list li a span.bi {
    padding-right: 8px;
}

div.fold-out-menu-right ul.fold-out-menu-list li a,
div.fold-out-menu-right ul.fold-out-menu-list li div.fold-out-menu-item-wrapper {
    text-align: right;
}

div.fold-out-menu-right ul.fold-out-menu-list li a span.bi {
    padding-right: 0;
    padding-left: 8px;
}

html.no-touchevents ul.fold-out-menu-list li a:hover,
ul.fold-out-menu-list li a:focus,
ul.fold-out-menu-list li a:active {
    color: $color-header-font-highlight;
    background: $color-header-background;
    text-shadow: 0 0 2px $color-highlight-primary;
}

ul.fold-out-menu-list li a.active {
    color: $color-highlight-primary;
    text-shadow: 0 0 2px $color-highlight-primary;
}

@media screen and (min-width: 360px) {
    button#btn-hamburger {
        height: 60px;
        width: 66px;
        padding: 10px 19px 10px 17px;
    }

    button#btn-hamburger span.icon-bar {
        width: 30px;
        height: 4px;
        margin: 6px 0;
    }

    div.fold-out-menu.open {
        top: 59px;
    }
}

@media screen and (min-width: 480px) {
    div.fold-out-menu {
        width: auto;
    }

    div.fold-out-menu-right {
        right: -500px;
    }

    div.fold-out-menu-right.open {
        right: 0;
    }

    div.fold-out-menu-left {
        left: -500px;
    }

    div.fold-out-menu-left.open {
        left: 0;
    }

    html.csstransforms3d div.fold-out-menu-right.open {
        right: -500px;
        transform: translate3d(-500px, 0, 0);
    }

    html.csstransforms3d div.fold-out-menu-left.open {
        left: -500px;
        transform: translate3d(500px, 0, 0);
    }
}

@media screen and (min-width: 1024px) {
    button#btn-hamburger {
        display: none;
    }
}

/*
 * 2.1.4 Language Menu
 */
div#language-selector {
    position: relative;
    width: 100%;
    height: 28px;
    background: transparent;
    line-height: 1;
    color: $color-header-font;
    cursor: pointer;
}

div#language-selector:active,
div#language-selector:focus,
div#language-selector.in-use,
html.no-touchevents div#language-selector:hover {
    background: $color-header-background;
}

div#language-selector ul#language-selector-list {
    position: absolute;
    width: 100%;
    height: auto;
    top: -500px;
    z-index: 9105;
    margin: 0;
    padding: 0 0 5px;
    background: $color-header-background;
    list-style: none;
    white-space: nowrap;
}

div#language-selector ul#language-selector-list.open {
    top: 0;
    z-index: 9106;
}

html.csstransforms3d div#language-selector ul#language-selector-list {
    position: relative;
    top: -500px;
    transform: translate3d(0, 0, 0);
}

html.csstransforms3d div#language-selector ul#language-selector-list.open {
    top: -500px;
    transform: translate3d(0, 500px, 0);
}

div#language-selector ul#language-selector-list li a,
div#language-selector ul#language-selector-list li div.active-language {
    line-height: 1;
    display: block;
    padding: 7px 15px 8px 12px;
    color: $color-header-font;
    vertical-align: middle;
}

div#language-selector ul#language-selector-list li div.active-language {
    color: $color-highlight-primary;
    cursor: auto;
}

div#language-selector ul#language-selector-list li a:active,
div#language-selector ul#language-selector-list li a:focus,
html.no-touchevents div#language-selector ul#language-selector-list li a:hover {
    color: $color-header-font-highlight;
    background: $color-header-background-shade-1;
    text-shadow: 0 0 2px $color-highlight-primary;
}

div#language-selector-active-language {
    position: relative;
    z-index: 9120;
    height: 28px;
    padding: 7px 15px 8px 12px;
    overflow: visible;
    background: $color-header-background-shade-1;
}

div#language-selector:active div#language-selector-active-language,
div#language-selector:focus div#language-selector-active-language,
div#language-selector.in-use div#language-selector-active-language,
html.no-touchevents div#language-selector:hover div#language-selector-active-language {
    background: $color-header-background;
}

div#language-selector div#language-selector-active-language:active,
div#language-selector div#language-selector-active-language:focus,
html.no-touchevents div#language-selector div#language-selector-active-language:hover {
    color: $color-header-font-highlight;
    text-shadow: 0 0 2px $color-highlight-primary;
}

div#language-selector div#language-selector-active-language:active .flag,
div#language-selector div#language-selector-active-language:focus .flag,
html.no-touchevents div#language-selector div#language-selector-active-language:hover .flag {
    box-shadow: 0 0 2px 1px $color-highlight-primary;
}

div#language-selector-active-language span {
    display: block;
    float: right !important;
}

div#language-selector ul#language-selector-list .flag,
div#language-selector-active-language .flag {
    position: relative;
    display: inline-block;
    float: none;
    margin: 1px 0 0 8px;
    width: 20px;
    height: 12px;
    background-image: url('#{$image-path}/flags.png') !important;
    background-repeat: no-repeat;
    background-size: 100%;
    filter: grayscale(70%);
}

div#language-selector ul#language-selector-list .flag {
    margin: -2px 0 0 8px;
    vertical-align: middle;
}

div#language-selector ul#language-selector-list li a:active .flag,
div#language-selector ul#language-selector-list li a:focus .flag,
html.no-touchevents div#language-selector ul#language-selector-list li a:hover .flag {
    filter: grayscale(40%);
    box-shadow: 0 0 2px 1px $color-highlight-primary;
}

.flag-gb {
    background-position: -0px -0px;
}

.flag-german,
.flag-de {
    background-position: -0px -12px;
}

.flag-spanish,
.flag-es {
    background-position: -0px -24px;
}

.flag-french,
.flag-fr {
    background-position: -0px -36px;
}

.flag-hu {
    background-position: -0px -48px;
}

.flag-it {
    background-position: -0px -60px;
}

.flag-dutch,
.flag-nl {
    background-position: -0px -72px;
}

.flag-pl {
    background-position: -0px -84px;
}

.flag-pt {
    background-position: -0px -96px;
}

div.main-heading {
    display: none;
}

@media screen and (min-width: 480px) {
    div#language-selector ul#language-selector-list {
        height: auto;
        width: auto;
        padding: 5px 0;
        position: absolute;
        top: -500px;
        right: auto;
        left: 0;
    }

    div#language-selector ul#language-selector-list.open {
        top: -5px;
        right: 100%;
        left: auto;
    }

    html.csstransforms3d div#language-selector ul#language-selector-list {
        position: absolute;
        top: -5px;
        right: 100%;
        left: auto;
        transform: translate3d(-250px, -250px, 0) scale3d(0, 0, 1);
    }

    html.csstransforms3d div#language-selector ul#language-selector-list.open {
        top: -5px;
        right: 100%;
        left: auto;
        transform: translate3d(0, 0, 0) scale3d(1, 1, 1);
    }

    div#language-selector ul#language-selector-list li a,
    div#language-selector ul#language-selector-list li div.active-language {
        padding: 7px 15px 8px;
    }
}

/* -----------------------------------------------
 * 2.2 Footer
 * -------------------------------------------- */

#footer {
    background: $color-footer-background;
    color: $color-footer-font;
    border-top: 1px solid $color-footer-background-shade-1;
}

#footer-container {
    padding: 10px 12px 8px;
    margin: 0 auto;
    max-width: 1180px;
    overflow: hidden;
    font-family: $typography-body-family;
}

#footer-links {
    width: 100%;
    margin: 0 0 10px;
}

#footer-links > ul {
    margin: 0;
    padding: 0;
    list-style: none;
    text-align: center;
    line-height: $typography-sm-lineHeight-small-rel;
    font-size: $typography-sm-size;
}

#footer-links > ul .dropdown-menu > li > a {
    padding: 4px 8px;
}

#footer-links ul#footer-links-1 {
    margin: 2px 0 0;
}

#footer-links > ul > li {
    margin: 0 0 4px;
}

#footer-links > ul > li > a {
    color: $color-footer-links-font;
    font-weight: $typography-body-weight;
}

#footer-links > ul > li > a.active {
    color: $color-highlight-primary;
    text-shadow: 0 0 2px $color-highlight-primary;
}

#footer-links > ul > li a span.bi {
    padding-right: 12px;
}

#footer-links > ul > li > a:focus,
#footer-links > ul > li > a:active,
html.no-touchevents #footer-links > ul > li > a:hover {
    color: $color-footer-font-highlight;
    text-shadow: 0 0 2px $color-highlight-primary;
}

#footer-copyright-notice {
    margin: 0;
}

#footer-copyright-notice p {
    margin: 0;
    font-size: $typography-xs-size;
    line-height: $typography-xs-lineHeight-extraSmall-rel;
    text-align: justify;
}

#footer-copyright-notice p img.footer-affiliates.footer-affiliates-inline {
    display: none;
    float: right;
    margin: 5px 0 0 15px;
}

#footer-copyright-notice img.footer-affiliates.footer-affiliates-block {
    display: inline-block;
    margin: 0 auto 12px;
}

#footer-copyright-notice div#footer-affiliates-block-wrap {
    text-align: center;
}

#footer-links ul li#footer-social-channels {
    line-height: 1;
    margin: 0;
    padding-top: 4px;
}

a.social-link {
    display: inline-block;
    width: 21px;
    height: 21px;
    margin: 0 7px 0 0;
    background: url('#{$image-path}/social-icon-sprites.png') no-repeat;
    background-size: 63px;
    filter: grayscale(60%);
}

a.social-link:active,
a.social-link:focus,
html.no-touchevents a.social-link:hover {
    filter: grayscale(20%);
    box-shadow: 0 0 2px 1px $color-highlight-primary;
}

a.social-link-twitter {
    background-position: -21px -0px;
}

a.social-link-google-plus {
    background-position: -42px -0px;
}

@media screen and (min-width: 360px) {
    #footer-links {
        margin: 0 0 15px;
    }

    #footer-container {
        padding: 13px 15px 11px;
    }
}

@media screen and (min-width: 400px) {
    #footer-copyright-notice p img.footer-affiliates.footer-affiliates-inline {
        display: inline-block;
    }

    #footer-copyright-notice div#footer-affiliates-block-wrap {
        display: none;
    }
}

@media screen and (min-width: 480px) {
    #footer-container {
        padding: 20px 22px 19px;
    }
}

@media screen and (min-width: 600px) {
    #footer-links {
        width: 200px;
        margin: 0;
        float: left;
    }

    #footer-links > ul {
        width: 100%;
        float: none;
        text-align: left;
    }

    #footer-copyright-notice {
        margin: 0 0 0 200px;
    }
}

@media screen and (min-width: 920px) {
    #footer-links {
        width: 400px;
    }

    #footer-links > ul {
        width: 50%;
        float: left;
    }

    #footer-links ul#footer-links-2 {
        margin: 2px 0 0;
    }

    #footer-copyright-notice {
        margin: 0 0 0 400px;
    }
}

@media screen and (min-width: 1200px) {
    #footer-links > ul {
        font-size: $typography-sm-size-large;
        line-height: $typography-sm-lineHeight-small-large-rel;
    }

    #footer-copyright-notice p {
        font-size: $typography-xs-size-large;
        line-height: $typography-xs-lineHeight-extraSmall-large-rel;
    }

    #footer-links ul#footer-links-1,
    #footer-links ul#footer-links-2 {
        margin: 0;
    }
}

/* -----------------------------------------------
 * 2.3 Sub Navigation
 * -------------------------------------------- */

/*
 * 2.3.1 Nav Tabs (CLA / cardManagement)
 */

.nav-tabs {
    border-bottom: 2px solid $color-gray-shade-1;
}

.nav-tabs > li {
    margin-bottom: -2px;
}

.nav-tabs > li > a:hover {
    border-color: transparent;
}

.nav > li > a:focus,
.nav > li > a:hover {
    color: $color-highlight-primary;
    background-color: transparent;
}

.nav-tabs > li.active > a,
.nav-tabs > li.active > a:hover,
.nav-tabs > li.active > a:active,
.nav-tabs > li.active > a:focus {
    background-color: $color-body-background;
    border: 2px solid $color-gray-shade-1;
    border-bottom-color: transparent;
}

/*
 * 2.3.2 Nav Pills (Viabuy)
 */

.nav-pills a {
    color: $color-body-font;
    padding: 0 !important;
    margin-bottom: $typography-main-lineHeight-half-max-px;
    cursor: pointer;
}

.nav-pills li:nth-child(2n+1) a {
    margin-bottom: $typography-main-lineHeight-half-min-px;
}

@media screen and (min-width: 1200px) {
    .nav-pills a {
        margin-bottom: $typography-main-lineHeight-half-max-large-px;
    }

    .nav-pills li:nth-child(2n+1) a {
        margin-bottom: $typography-main-lineHeight-half-min-large-px;
    }
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:active,
.nav-pills > li.active > a:focus,
.nav-pills > li.active > a:hover,
.nav-pills a:active,
.nav-pills a:hover,
.nav-pills a:focus {
    background-color: transparent !important;
    color: $color-highlight-primary-shade-2 !important;
}

.nav-stacked > li + li {
    margin-top: 0;
}

/*
 * 2.3.3 Sticky Nav Pills (myAccount)
 */

.nav-sidenav-sticky {
    z-index: 1;
    background: transparent;
}

/*
 * 2.2.4 Dropdown Menus (cardManagement)
 */

ul.dropdown-menu {
    z-index: 9999;
    padding: 0.5rem;

    li {
        padding: 0;
    }
}

/* -----------------------------------------------
 * III. Content
 * -------------------------------------------- */

/* -----------------------------------------------
 * 3.1 Login
 * -------------------------------------------- */

html.js body.login .content {
    opacity: 0;
}

html.js body.login .content.js-shown {
    opacity: 1;
    transition: opacity .3s ease-in-out;
}

body.login div.container-header.collapsed {
    height: 60px;
    overflow: visible;
}

html.csstransforms3d body.login div.container-header.collapsed {
    transform: translate3d(0, 0, 0);
}

body.login div.logo-wrap-left {
    position: relative;
    z-index: 9120;
    display: none;
    float: left;
}

@media screen and (min-width: 260px) {
    body.login div.logo-wrap-left {
        display: block;
    }
}

body.login div.header-right {
    position: relative;
    z-index: 9120;
    float: right;
    width: auto;
    height: 100%;
}

body.login div#language-selector {
    position: static;
    height: 100%;
    font-size: $typography-foldOut-size;
}

body.login div#language-selector div#language-selector-active-language {
    height: 100%;
    padding: 18px 15px 0 12px;
    background: $color-header-background;
    white-space: nowrap;
}

body.login div#language-selector:active div#language-selector-active-language,
body.login div#language-selector:focus div#language-selector-active-language,
body.login div#language-selector.in-use div#language-selector-active-language,
html.no-touchevents body.login div#language-selector:hover div#language-selector-active-language {
    background: $color-header-background-shade-1;
}

body.login div#language-selector ul#language-selector-list {
    position: absolute;
    z-index: 9101;
    top: 49px;
    width: auto;
    right: -500px;
    background: $color-header-background-shade-1;
    padding: 5px 0;
    text-align: right;
}

body.login div#language-selector ul#language-selector-list,
body.login div#language-selector ul#language-selector-list.open,
html.csstransforms3d div#language-selector ul#language-selector-list,
html.csstransforms3d div#language-selector ul#language-selector-list.open {
    top: 49px;
}

@media screen and (min-width: 360px) {
    body.login div#language-selector ul#language-selector-list,
    body.login div#language-selector ul#language-selector-list.open,
    html.csstransforms3d div#language-selector ul#language-selector-list,
    html.csstransforms3d div#language-selector ul#language-selector-list.open {
        top: 59px;
    }

    body.login div#language-selector div#language-selector-active-language {
        padding-top: 23px;
    }
}

body.login div#language-selector ul#language-selector-list.open {
    right: 0;
}

html.csstransforms3d body.login div#language-selector ul#language-selector-list {
    right: -500px;
    transform: translate3d(0, 0, 0);
}

html.csstransforms3d body.login div#language-selector ul#language-selector-list.open {
    right: -500px;
    transform: translate3d(-500px, 0, 0);
}

body.login div#language-selector ul#language-selector-list li {
    display: list-item;
}

body.login div#language-selector ul#language-selector-list li a {
    display: inline-block;
    white-space: nowrap;
}

body.login div#language-selector ul#language-selector-list li a:active,
body.login div#language-selector ul#language-selector-list li a:focus,
html.no-touchevents body.login div#language-selector ul#language-selector-list li a:hover {
    background: $color-header-background;
    text-shadow: 0 0 2px $color-highlight-primary;
}

body.login .card-image {
    max-width: 261px;
    margin: 0 auto ($typography-main-lineHeight-px * 2);
}

@media screen and (min-width: 540px) {
    body.login .col-card-image-xxs .card-image {
        margin: 0 auto $typography-main-lineHeight-px;
    }
}

@media screen and (min-width: 650px) {
    body.login .col-card-image-xs .card-image {
        margin: 0 auto $typography-main-lineHeight-px;
    }
}

@media screen and (min-width: 768px) {
    body.login .col-card-image .card-image {
        margin: 0 auto $typography-main-lineHeight-px;
    }
}

html.svg .login-links svg,
.login-links img.svg-fallback-img {
    float: left;
    height: $typography-main-lineHeight-px * 2;
    margin: 0 5px 0 0;
}

html.svg .login-links svg * {
    fill: $color-body-font;
    transition: fill .3s ease;
}

@media screen and (min-width: 1200px) {
    html.svg .login-links svg,
    .login-links img.svg-fallback-img {
        height: ($typography-main-lineHeight-large-px * 2);
    }
}

.login-links a {
    display: block;
    min-height: 38px;
    line-height: 18px;
    color: $color-body-font;
    text-decoration: none;
}

.login-links a span {
    font-weight: 700;
    font-style: italic;
    border-bottom: 1px dotted $color-body-font;
    transition: color .3s ease, border-color .3s ease;
}

.login-links a:hover span {
    color: $color-highlight-primary;
    border-color: $color-highlight-primary;
}

html.svg .login-links a:hover svg * {
    fill: $color-highlight-primary;
}

html.ie div.login-link-icon {
    float: left;
    max-width: 55px;
}

@media screen and (min-width: 360px) {
    body.login div#language-selector ul#language-selector-list {
        top: 59px;
    }
}

@media screen and (min-width: 1200px) {
    body.login .card-image,
    body.login .col-card-image-xs .card-image {
        margin-bottom: $typography-main-lineHeight-large-px;
    }
}

table.account-selector {
    width: 100%;
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    font-weight: 700;
}

table.account-selector tbody tr {
    cursor: pointer;
    border-bottom: 1px solid $color-gray-shade-1;
}

table.account-selector tbody tr:last-child {
    cursor: pointer;
    border-bottom: none;
}

table.account-selector tbody tr:hover {
    color: $color-highlight-primary;
}

table.account-selector td {
    padding: ($typography-main-lineHeight-half-min-px + 2px) 3px ($typography-main-lineHeight-half-max-px - 3px);
}

table.account-selector th {
    padding: ($typography-main-lineHeight-half-min-px + 2px) 3px ($typography-main-lineHeight-half-max-px - 3px);
}

table.account-selector thead tr {
    border-bottom: 1px solid $color-body-font;
}

table.account-selector thead th {
    font-weight: 400;
}

@media screen and (min-width: 1200px) {
    table.account-selector {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
    }
}

/* -----------------------------------------------
 * 3.2 Password
 * -------------------------------------------- */

#pass-strength-meter {
    width: 100%;
    font-size: $typography-main-size;
    line-height: $typography-main-lineHeight-rel;
    padding: ($typography-main-lineHeight-half-min-px / 2 + 1px) $typography-main-lineHeight-half-max-px ($typography-main-lineHeight-half-min-px / 2);
    margin: ($typography-main-lineHeight-half-min-px / 2 - ($form-border-width * 2 - 2px)) 0;
    height: auto;
    background: #eee;
    border: $form-border-width solid $color-form-border;
    border-radius: $border-radius;
    color: #666;
}

@media screen and (min-width: 1200px) {
    #pass-strength-meter {
        font-size: $typography-main-size-large;
        line-height: $typography-main-lineHeight-large-rel;
        padding: ($typography-main-lineHeight-half-min-large-px / 2) $typography-main-lineHeight-half-max-large-px ($typography-main-lineHeight-half-min-large-px / 2);
        margin: ($typography-main-lineHeight-half-min-large-px / 2 - ($form-border-width * 2 - 2px)) 0;
    }
}

#pass-strength-meter.mismatch {
    background: #ffe4e1;
}

#pass-strength-meter.short {
    background: linear-gradient(to right, #ffe4e1 0%, #ffe4e1 30%, #eee 30%, #eee 100%);
}

#pass-strength-meter.okay {
    background: linear-gradient(to right, #fcf8e3 0%, #fcf8e3 60%, #eee 60%, #eee 100%);
}

#pass-strength-meter.good {
    background: linear-gradient(to right, #d9edf7 0%, #d9edf7 80%, #eee 80%, #eee 100%);
}

#pass-strength-meter.great {
    background: #dff0d8
}

/* -----------------------------------------------
 * 3.3 Payment Method Selection
 * -------------------------------------------- */

.payment-method {
    position: relative;
    padding: 20px 20px 30px;
    margin-bottom: 15px;
    background: #fff;
}

.payment-method .payment-title {
    margin-top: 0;
}

.payment-icon {
    display: block;
    max-width: 130px;
}

.payment-icon img.payment-image {
    max-width: 100%;
}

.payment-delivery {
    margin-top: -4px; /* temporary hack until proper global vertical alignment is implemented */
    text-align: right;
}

.payment-info {
    list-style: disc;
}

.payment-amount-inputs {
    display: none;
    padding-top: 15px;
}

.payment-amount-inputs .validation-error {
    bottom: -1.1em;
    left: 0;
    right: auto;
}

.payment-method-notice {
    padding-top: 20px;
}

.highlight-gray .form-control:focus,
.highlight-gray input[type="text"]:focus {
    outline: 0;
    border-color: rgba(204, 204, 204, 0.9);
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(204, 204, 204, 0.9) !important;
}

.payment-method-selector-button {
    position: absolute;
    width: 155px;
    height: 37px;
    background: url('#{$image-path}/VIABUY_LOAD_button_off.png') no-repeat right bottom;
    cursor: pointer;
}

.load-card-button {
    position: absolute;
    bottom: 0;
    right: 0;
    height: 25px;
    width: 184px;
    border: 0;
    cursor: pointer;
    background: url('#{$image-path}/VIABUY_LOAD_button_off.png') no-repeat right bottom;
}

.load-card-button:hover {
    background: url('#{$image-path}/VIABUY_LOAD_button_on.png') no-repeat right bottom;
}

.load-card-button span {
    position: absolute;
    bottom: 8px;
    right: 0;
    width: 160px;
    text-align: center;
    color: #61666c;
    font-size: 12px;
    font-family: Verdana, Helvetica, Arial, serif;
    line-height: 12px;
}

#payment-submit-button {
    position: absolute;
    bottom: 0;
    right: 0;
    z-index: 1;
    width: 184px;
    height: 25px;
    padding: 0 0 0 25px;
    border: 0;
    background: url('#{$image-path}/VIABUY_LOAD_button_off.png') no-repeat right bottom;
    font-size: 12px;
    font-family: Verdana, Helvetica, Arial, serif;
    color: #61666c;
    cursor: pointer;
}

#payment-submit-button:hover {
    background: url('#{$image-path}/VIABUY_LOAD_button_on.png') no-repeat right bottom;
    opacity: 1;
}

@media screen and (max-width: 767px) {
    .payment-method {
        padding: 15px 15px 30px;
        margin-bottom: 10px;
    }

    .payment-icon {
        margin-bottom: 10px;
    }

    .payment-delivery {
        text-align: left;
        margin-top: 0;
    }

    .payment-delivery p.yellow {
        margin-bottom: 0;
    }
}

@media screen and (max-width: 520px) {
    .payment-method {
        padding: 10px 15px 30px;
        margin-bottom: 10px;
    }

    .col-payment-icon,
    .col-payment-details,
    .col-payment-method-notice,
    .col-payment-amount-inputs {
        width: 100%;
        margin-left: 0;
    }
}

/* -----------------------------------------------
 * 3.4 My Card Portal
 * -------------------------------------------- */

.well .balance-value {
    margin-bottom: 0;
    line-height: 1;
}

#myCard .table-responsive {
    margin-bottom: 0;
}

.statement-table-wrap {
    margin-bottom: $typography-main-lineHeight-px;
}

.statement-table-wrap:last-child {
    margin-bottom: 0;
}

.transactions-noentries {
    font-style: italic;
    text-align: center;
}

.transaction-amount {
    text-align: right;
}

tr.transaction-row-missing {
    background-color: $color-message-error-light;
}

.table-striped > tbody > tr.transaction-row-missing:nth-of-type(odd) {
    background-color: $color-message-error-light;
}

tr.transaction-row-blocked,
tr.transaction-row-pending {
    background-color: $color-message-warning-light;
}

.table-striped > tbody > tr.transaction-row-blocked:nth-of-type(odd),
.table-striped > tbody > tr.transaction-row-pending:nth-of-type(odd) {
    background-color: $color-message-warning-light;
}

@media screen and (min-width: 1200px) {
    .statement-table-wrap {
        margin-bottom: $typography-main-lineHeight-large-px;
    }

    .statement-table-wrap:last-child {
        margin-bottom: 0;
    }
}

/* -----------------------------------------------
 * 3.5 Documents Tab
 * -------------------------------------------- */

ul.documents-list {
    margin-bottom: $typography-main-lineHeight-px;
}

ul.documents-list:last-child {
    margin-bottom: 0;
}

ul.documents-list .bi-file {
    font-size: $typography-main-size-px - 2px;
}

@media screen and (max-width: 520px) {
    ul.documents-list .bi-file {
        font-size: $typography-main-size-large-px - 3px;
    }
}

div.table-documents td.month-separator {
    text-align: center;
    font-weight: 700;
}

div.table-documents td a {
    text-decoration: underline;
}

div.table-documents td a:hover,
div.table-documents td a:focus,
div.table-documents td a:active {
    color: $color-highlight-primary;
}

/* -----------------------------------------------
 * 3.6 Glossary
 * -------------------------------------------- */

div.table-glossary-wrap {
    font-size: $typography-sm-size;
    margin: 0 0 $typography-main-lineHeight-px;
    padding: ($typography-main-lineHeight-half-max-px - $title-secondary-border-width) ($typography-main-lineHeight-half-min-px - $title-secondary-border-width) ($typography-main-lineHeight-half-min-px - $title-secondary-border-width);
    border: $title-secondary-border-width solid $color-gray-shade-1;
    border-radius: $border-radius;
    background: #fff;
}

.table-glossary {
    background: #fff;
}

.table-glossary td,
.table-glossary th {
    padding: ($typography-main-lineHeight-px / 7) $typography-main-lineHeight-half-max-px;
    vertical-align: top;
}

@media screen and (min-width: 1200px) {
    div.table-glossary-wrap {
        font-size: $typography-sm-size-large;
        margin: 0 0 $typography-main-lineHeight-large-px;
        padding: ($typography-main-lineHeight-half-max-large-px - $title-secondary-border-width) ($typography-main-lineHeight-half-min-large-px - $title-secondary-border-width) ($typography-main-lineHeight-half-min-large-px - $title-secondary-border-width);
    }

    .table-glossary td,
    .table-glossary th {
        padding: ($typography-main-lineHeight-large-px / 8) $typography-main-lineHeight-half-max-large-px;
    }
}

/* -----------------------------------------------
 * 3.7 Order Process
 * -------------------------------------------- */

body.order div.container-header {
    height: 53px;
}

body.order div#header-background {
    display: none;
}

body.order div.logo-wrap-left {
    float: left;
}

body.order img.logo {
    display: none;
    margin: 16px 15px;
}

.order-progress-indicator {
    margin: 6px auto 0;
    float: none;
    width: 90%;
}

.progress-indicator-step * {
    box-sizing: content-box;
}

.progress-indicator-step-clickable {
    cursor: pointer;
}

.progress-indicator-step * {
    transition: background-color .4s ease-in-out, color .4s ease-in-out, border-color .4s ease-in-out;
}

html.svg .progress-indicator-step .step-svg {
    height: 36px;
    width: auto;
    text-align: center;
}

html.svg .progress-indicator-step .step-svg > div,
html.svg .progress-indicator-step .step-svg svg {
    height: 100%;
}

html.svg .progress-indicator-step .step-svg svg *,
.progress-indicator-step.with-svg .step-svg svg * {
    fill: $color-progress-future !important;
}

html.svg .progress-indicator-step.done .step-svg svg *,
.progress-indicator-step.with-svg.done .step-svg svg * {
    fill: $color-progress-past !important;
}

html.svg .progress-indicator-step.current .step-svg svg *,
.progress-indicator-step.with-svg.current .step-svg svg * {
    fill: $color-progress-now !important;
}

.progress-indicator-step .step-background-outer {
    width: 30px;
    height: auto;
    margin-left: auto;
    margin-right: auto;
    border-color: $color-progress-future;
}

.progress-indicator-step .step-background-inner {
    width: 30px;
    height: 30px;
    text-align: center;
    line-height: 30px;
    margin: 6px 0 4px 0;
    background-color: $color-progress-future;
}

.progress-indicator-step.done .step-background-outer {
    border-color: $color-progress-past;
}

.progress-indicator-step.done .step-background-inner {
    background-color: $color-progress-past;
}

.progress-indicator-step.current .step-background-outer {
    width: 36px;
    height: 36px;
    border-radius: 100%;
    border: solid 2px $color-progress-now;
    text-align: center;
    position: relative;
    line-height: 35px;
}

.progress-indicator-step.current .step-background-inner {
    width: 30px;
    height: 30px;
    border-radius: 100%;
    background-color: $color-progress-now;
    position: relative;
    margin: 3px 0 0 3px;
}

.progress-indicator-step .step-number {
    position: relative;
    line-height: 32px;
    padding-left: 1px;
    font-size: 18px;
    font-weight: 700;
    color: #fff;
}

.progress-indicator-step.current .step-number {
    line-height: 32px;
}

.progress-indicator-step .step-title {
    color: $color-progress-title;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 0;
    white-space: nowrap;
}

.progress-indicator-step.done .step-title {
    color: $color-progress-past;
}

.progress-indicator-step.current .step-title {
    color: $color-progress-now;
    font-weight: 700;
}

body.order .card-image {
    max-width: 261px;
    margin: 0 auto 21px;
}

@media screen and (min-height: 360px) {
    body.order div#fixed-header-push {
        height: 53px;
    }

    html.csstransforms3d body.order div.container-header.collapsed {
        transform: translate3d(0, -53px, 0);
    }
}

@media screen and (min-height: 340px) and (min-width: 480px) {
    body.order div#fixed-header-push {
        height: 53px;
    }

    html.csstransforms3d body.order div.container-header.collapsed {
        transform: translate3d(0, -53px, 0);
    }
}

@media screen and (min-height: 340px) and (min-width: 768px) {
    body.order div#fixed-header-push {
        height: 72px;
    }

    html.csstransforms3d body.order div.container-header.collapsed {
        transform: translate3d(0, -72px, 0);
    }
}

@media screen and (min-height: 720px) and (min-width: 768px) {
    body.order div.container-header.collapsed {
        height: 72px;
        overflow: visible;
    }

    html.csstransforms3d body.order div.container-header.collapsed {
        transform: translate3d(0, 0, 0);
    }
}

@media screen and (min-width: 420px) {
    body.order div.logo-wrap-left {
        display: block;
    }

    body.order img.logo {
        display: inline-block;
    }

    .order-progress-indicator {
        width: 58%;
        float: right;
    }
}

@media screen and (min-width: 480px) {
    body.order img.logo {
        margin: 14px 15px;
    }
}

@media screen and (min-width: 768px) {
    body.order div.container-header {
        height: 72px;
    }

    body.order img.logo {
        height: 30px;
        margin: 21px;
    }

    body.order .card-image {
        max-width: none;
    }

    body.order .step-1 .card-image {
        max-width: 261px;
    }

    .order-progress-indicator {
        width: 65%;
        float: right;
    }
}

@media screen and (min-width: 1050px) {
    .order-progress-indicator {
        width: 69%;
    }
}

@media screen and (min-width: 1100px) {
    .order-progress-indicator {
        width: 72%;
    }
}

@media screen and (min-width: 1250px) {
    .order-progress-indicator {
        float: right;
        width: 74%;
    }
}

@media screen and (min-width: 1400px) {
    .order-progress-indicator {
        float: none;
    }
}

body.order .order-number-wrap h3,
body.order .order-number-wrap :first-child {
    margin-top: 0;
}

body.order .order-number-wrap p {
    margin-bottom: 15px; /* tbd */
}

body.order .emphasis {
    font-style: italic;
    font-size: $typography-main-size;
    text-decoration: underline;
}

body.order .inline-large-font { /* tbd */
    margin: 0;
    font-weight: bold;
    font-size: 1.2308em;
    vertical-align: middle;
}

body.order .btn-order {
    padding: 9px 45px;
    font-size: 20px;
    line-height: 1;
    font-style: normal;
    font-weight: bold;
    color: rgb(51, 51, 51);
    background-color: rgb(251, 180, 35) !important;
    border-radius: 5px;
    transition: opacity 0.5s ease-in-out;
}

body.order .btn-order-disabled {
    opacity: .65;
    box-shadow: none;
    cursor: not-allowed;
}

body.order .ssl-seal {
    float: left;
    margin: 0 8px 0 0;
}

body.order .ssl-text {
    color: #666;
    font-size: 12px;
    margin-bottom: 0;
    display: table-cell;
    vertical-align: middle;
}

body.order .submit-wrap .encryption {
    display: table;
    width: auto;
    margin-top: 20px;
}

body.order .pricing img {
    width: 100%;
    max-width: 261px;
    margin-bottom: 1em;

    filter: drop-shadow(0 11px 11px rgba(224, 224, 224, 0.32));
}

@media screen and (max-width: 767px) {
    body.order .card-image[style] {
        position: static !important;
    }
}

@media screen and (max-width: 479px) {
    body.order .submit-wrap .encryption {
        /*margin-top: $typography-main-lineHeight-px + 7px;*/
    }

    body.order .ssl-text {
        max-width: 200px;
    }
}

@media screen and (min-width: 1200px) {
    body.order .emphasis {
        font-size: $typography-main-size-large;
    }
}

/* Activation-only products fix (context is login, layout is order-like) */
body.order.activation-only div#language-selector {
    display: none;
}

/*
 * 3.7.1 Card Selection Toggle Switch (Renewals)
 */

.toggle-switch {
    width: 200px;
    height: 42px;
    border: 2px solid #e6e6e6;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.5s ease-in-out;
}

.toggle-switch-option {
    width: 50%;
    float: left;
    font-weight: 700;
    font-size: 16px;
    line-height: 38px;
    text-align: center;
    transition: color 0.5s ease-in-out;
}

.toggle-switch-button {
    width: 96px;
    height: 34px;
    margin: 2px;
    border-radius: 4px;
    transition: background-color 0.5s ease-in-out, color 0.5s ease-in-out, margin-left 0.5s ease-in-out;
}

.toggle-switch.selected-option-2 .toggle-switch-button {
    margin-left: 98px;
}

.toggle-switch.selected-option-1 .toggle-switch-option-1 {
    color: #eee;
}

.toggle-switch.selected-option-2 .toggle-switch-option-2 {
    color: #eee;
}

.csscalc .col-card-selection {
    width: 100%;
    max-width: 350px;
    margin: 0 calc((100% - 350px) / 2);
}

@media screen and (max-width: 350px) {
    .csscalc .col-card-selection {
        margin: 0;
    }
}

#card-selection-toggle-wrap {
    margin-bottom: 10px;
}

#card-selection-toggle-switch {
    margin: 0 auto;
}

#card-selection-toggle-switch.selected-option-gold {
    border-color: #d1b876;
}

#card-selection-toggle-switch.selected-option-black {
    border-color: #191919;
}

#card-selection-toggle-switch.selected-option-gold .toggle-switch-button {
    background: linear-gradient(to bottom, #d1b876 0%, #e0c17b 40%, #bfaf7c 100%);
}

#card-selection-toggle-switch.selected-option-black .toggle-switch-button {
    background: linear-gradient(to bottom, #444441 0%, #4c4b47 40%, #242422 100%);
}

.card-selection-image-wrap {
    overflow: hidden;
    height: 169px;
}

.card-selection-image-container {
    position: relative;
    height: 169px;
}

.card-selection-image {
    position: absolute;
    height: 169px;
    width: 100%;
    top: 0;
    left: 0;
    transition: left 0.5s ease-in-out;
}

#card-selection-image-black {
    left: -100%;
}

.card-selection-image-container.selected-option-black #card-selection-image-gold {
    left: 100%;
}

.card-selection-image-container.selected-option-black #card-selection-image-black {
    left: 0;
}

.card-selection-image-container .card-image[style] {
    position: static !important;
}

.card-selection-image-container .col-card-image {
    float: none;
    width: 100%;
}

.card-selection-image-container .card-image {
    cursor: pointer;
    max-width: 261px;
    margin: 0 auto 30px;
}

/* Hacky Fix */
.order .formResendSMS {
    margin-top: -10px;
}

/*
 * 3.7.2 Verification process
 */

.edit-info {
    margin-bottom: 1em;
}

.edit-info-text,
.form-info-edit {
    display: inline;
    float: none;
}

.edit-info-text {
    margin: 0;
}

.edit-number-btn {
    margin: 0;
}

.faux-form {
    margin-top: 1.5em;
}

.summary-data {
    border: 2px solid #DDD;
    background: transparent;
    padding: 3em;
    margin-bottom: 3em;
}

.summary-data-form {
    margin: 0 0 4em;
    width: 70%;
}

.summary-data .alert-msg {
    color: $color-message-error-dark;
    font-weight: bold;
}

.summary-data-form .tabular-data-row {
    overflow: visible;
}

.envelope-box {
    background: #fff;
    border: 2px solid #DDD;
    padding: 3.5em;
    position: relative;
    margin-bottom: 3em;
}

.envelope-box-shipping {
    margin: 0;
    width: 63%;
}

.envelope-box-shipping span {
    display: block;
}

.envelope-box-img {
    background: #fff;
    position: absolute;
    right: 1.8em;
    top: 2.2em;
    width: 33%;
    max-width: 12em;
}

.order-btn {
    font-size: 1.7em;
}

.order-btn .bi {
    font-size: 0.7em;
    top: 0;
    right: -3px;
}

.order-verification-code-app:after {
    clear: both;
    content: "";
    display: block;
    height: 0;
    width: 0;
}

.order-verification-code-app {
    display: inline-block;
}

.order-verification-code-app input,
.order-verification-code-app input:focus {
    float: left;
    margin-right: .7em;
}

@media screen and (max-width: 480px) {
    .envelope-box {
        padding: 2.5em;
        margin-bottom: 2em;
    }

    .summary-data {
        border-right: none;
        border-left: none;
        padding: 3em 0;
        margin-bottom: 2em;
    }
}

/* -----------------------------------------------
 * 3.8 Source of Funds
 * -------------------------------------------- */

.source-funds-salary,
.source-funds-bank,
.source-funds-support,
.source-funds-other {
    display: none;
}

.source-profile-salary .source-funds-salary,
.source-profile-bank .source-funds-bank,
.source-profile-support .source-funds-support,
.source-profile-other .source-funds-other {
    display: block;
}

.source-of-funds-header {
    margin-bottom: 1em;
}

/* -----------------------------------------------
 * 4.1 Card Load Page - Left Panel
 * -------------------------------------------- */
.loadcard-panel {
    border: 2px solid #DDD;
    margin-top: 1em;
}

.loadcard-panel__balance {
    padding: 2em;
}

.loadcard-panel__title {
    font-size: 1.7em;
    text-transform: uppercase;
}

.loadcard-panel__amount {
    font-size: 2.5em;
    display: block;
    line-height: 1.2;
}

.loadcard-panel__amount em {
    font-style: normal;
    font-size: .6em;
}

.loadcard-panel__balance td {
    padding-right: 0.5em;
    font-size: $typography-sm-size;
}

.loadcard-panel__limit .loadcard-panel__details {
    padding: 0 20px 20px;
}

.loadcard-panel__idv {
    position: relative;
    z-index: 1;
    overflow: hidden;
}

.loadcard-panel__btn {
    font-size: $typography-sm-size;
    white-space: normal;
    max-width: 100%;
}

.loadcard-panel__btn .bi {
    top: 1px;
    font-size: .8em;
    right: -5px;
}

.loadcard-panel__details .details-title {
    font-size: 1.7em;
    display: block;
    text-transform: uppercase;
}

.loadcard-panel__details .details-status span {
    color: $color-highlight-primary;
}

.loadcard-panel__details .details-status,
.loadcard-panel__details .details-left {
    display: block;
    font-size: $typography-sm-size;
}

.loadcard-panel__details .details-left {
    color: $color-highlight-primary;
}

.loadcard-panel__details .details-cta {
    font-size: $typography-xs-size;
    text-decoration: underline;
}

.loadcard-panel__details .details-cta:hover {
    text-decoration: none;
}

.loadcard-panel__limit {
    transition: background-color .2s;
    border-top: 2px solid #ddd;
}

.loadcard-panel__limit--opened,
.loadcard-panel__limit--opened h5 a,
.loadcard-panel__limit:hover {
    background: #f2f2f2;
}

.loadcard-panel__limit h5 {
    position: relative;
    z-index: 2;
}

.loadcard-panel__limit h5 a {
    position: relative;
    padding: 20px;
    display: block;
}

.loadcard-panel__limit h5 i {
    position: absolute;
    right: 1.4em;
    top: 1.9em;
    font-size: 0.7em;
}

.loadcard-panel--isLoading {
    padding: 20px;
    text-align: center;
}

/* -----------------------------------------------
* 4.2 Card Load Page - SelectCountry
* -------------------------------------------- */
.select-country__title {
    display: block;
    font-size: $typography-sm-size;
    margin-bottom: .5em;
}

.select-country__combo {
    position: relative;
}

.select-country__selected {
    border: 2px solid #ddd;
    background: #fff;
    padding: 10px;
    position: relative;
    z-index: 1;
}

.select-country__selected:after {
    content: '';
    display: block;
    clear: both;
}

.select-country__selected__name {
    display: block;
    float: left;
    margin-left: 2%;
    line-height: 1.3em;
    border: 0;
    font-size: 1.4em;
    background: transparent;
    outline: none;
    max-width: 78%;
    width: 30em;
}

.select-country__selected .bi {
    float: left;
    margin-left: 8px;
    margin-top: 1px;
    font-size: 12px;
}

.select-country__selected__dropdown {
    display: block;
    max-width: 85%;
    float: left;
    cursor: pointer;
    margin-top: 3.4px;
    word-break: break-all
}

.touchevents .select-country__selected__dropdown {
    padding: .15em 0 0;
}

.select-country__list {
    max-height: 175px;
    overflow-y: scroll;
    margin: 0;
    padding: 0;
    border: 2px solid #ddd;
    font-size: $typography-xs-size;
    position: absolute;
    width: 100%;
    top: 97%;
    z-index: 5;
}

.select-country__item {
    line-height: 1.5em;
    display: block;
    background: #fff;
    cursor: pointer;
}

.select-country__item.selected {
    background: #e8e8e8;
}

.select-country__item__wrap {
    display: block;
    padding: 10px;
    font-size: 13px;
}

.select-country__item__wrap:after {
    content: '';
    display: block;
    clear: both;
}

.select-country__item__name {
    max-width: 85%;
    float: left;
    margin-left: 8px;
    line-height: 1.3em;
    font-style: normal;
    word-break: break-all;
}

/* -----------------------------------------------
 * 4.3 Card Load Page - Load Card Methods
 * -------------------------------------------- */
.loadcard-methods {
    margin: 0 0 2em;
}

.loadcard-methods__list {
    border-bottom: 0 none;
    padding: 0;
    list-style: none;
    border-top: 2px solid #ddd;
}

.loadcard-methods__item {
    position: relative;
    background: #f2f2f2;
    border-left: 2px solid #ddd;
    border-right: 2px solid #ddd;
    border-bottom: 2px solid #ddd;
    overflow: hidden;
}

.loadcard-methods__wrap {
    padding: 2em 3em 3em 3.5em;
    min-height: 90px;
    position: relative;
    z-index: 3;
    background: #fff;
}

.loadcard-methods__open {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
}

.loadcard-methods__icon {
    position: absolute;
    top: 2.5em;
    left: 1.3em;
    z-index: -1;
}

.loadcard-methods__title {
    font-size: 2em;
    margin: 0 0 .2em;
    line-height: 1em;
    font-weight: 400;
    text-transform: uppercase;
    display: block;
    word-wrap: break-word;
}

.loadcard-methods__subtitle {
    font-size: $typography-sm-size;
    display: block;
    color: #999;
    line-height: 1.4;
}

.loadcard-methods--highlight {
    color: $color-highlight-primary;
}

.loadcard-methods__fieldset,
.loadcard-methods__details {
    padding: 4em;
    font-size: .9em;
}

.loadcard-methods__form-wrap {
    margin: 0;
}

.loadcard-methods__logo {
    position: absolute;
    right: 3em;
    max-height: 50px;
    max-width: 95px;
    width: 95px;
    top: 50%;
    transform: translate(0, -50%);
}

.loadcard-methods__logo.cashDepositGermanBank,
.loadcard-methods__logo.internationalWire,
.loadcard-methods__logo.polishDomesticWire,
.loadcard-methods__logo.bitpay {
    max-height: none;
    max-width: 60px;
}

.loadcard-methods__form {
    overflow: hidden;
    position: relative;
    z-index: 2;
}

.loadcard-methods__form:before,
.loadcard-methods__form:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    background: #ddd;
}

.loadcard-methods__form:before {
    height: 2px;
    width: 100%;
    left: 0;
}

.loadcard-methods__form:after {
    width: 2px;
    height: 100%;
    left: 50%;
}

.loadcard-methods__details span,
.loadcard-methods__details a,
.loadcard-methods__details li {
    font-size: 1.6em;
    line-height: 1.5;
}

.loadcard-methods__details span {
    margin-right: .3em;
}

.loadcard-methods__details a {
    text-decoration: underline;
}

.loadcard-methods__details a:hover {
    text-decoration: none;
}

.loadcard-methods__details ul {
    list-style: disc;
    margin: 1.5em 0;
}

@media screen and (max-width: 1200px) {
    .loadcard-methods__details span,
    .loadcard-methods__details a,
    .loadcard-methods__details li {
        font-size: 1.4em;
        line-height: 1.5;
    }
}

@media screen and (max-width: 991px) {
    .loadcard-methods__fieldset,
    .loadcard-methods__details {
        padding: 2em;
    }
}

@media screen and (max-width: 767px) {
    .loadcard-methods__logo {
        right: 2em;
        max-width: 80px;
        width: 80px;
    }
    .loadcard-methods__title {
        max-width: calc(100% - 80px);
        font-size: 1.6em;
    }
    .loadcard-methods__subtitle {
        max-width: calc(100% - 80px);
    }
    .loadcard-methods__form:after {
        display: none;
    }
    .loadcard-methods__details {
        border-top: 2px solid #ddd;
    }
    .loadcard-methods__fieldset,
    .loadcard-methods__details {
        padding: 3em;
    }
}

/* -----------------------------------------------
 * 4.4 Widget Modal
 * -------------------------------------------- */
// TODO(Davit): move ▼ to corresponding component
.widget-modal-content {
    border: 2px solid $color-highlight-primary;
}
// TODO(Davit): move ▲ to corresponding component

.loading-spinner {
    width: 36px;
    height: 36px;
}

/* -----------------------------------------------
 * 4.5 Generic Selectbox
 * -------------------------------------------- */
.gen-select {
    position: relative;
}

.gen-select__native {
    width: 100%;
    background: #fff;
    padding: 1em;
    border-radius: 0;
    border: 2px solid #ddd;
}

.gen-select__selected {
    border: 2px solid #ddd;
    background: #fff;
    padding: 0.85em 3.5em 0.85em 0.8em;
    font-size: $typography-sm-size;
    position: relative;
    z-index: 1;
    cursor: pointer;
    display: block;
    line-height: 1.45em;
}

.gen-select__icon {
    position: absolute;
    right: 1.2em;
    top: 1.2em;
}

.gen-select__item {
    padding: 0.8em 3.5em 0.8em 0.8em;
    border-bottom: 2px solid #ddd;
    cursor: pointer;
    background: #EFEFEF;
    transition: background .2s;
}

.gen-select__item:hover,
.gen-select__item--active {
    background: #FBB100
}

.gen-select__list {
    border: 2px solid #ddd;
    border-bottom: 0 none;
    max-height: 17em;
    overflow-y: auto;
    margin: 0;
    padding: 0;
    font-size: $typography-xs-size;
    position: absolute;
    width: 100%;
    top: 97%;
    z-index: 4;
    background: #fff;
}

/* -----------------------------------------------
 * 4.6 Current Address (modal overlay)
 * -------------------------------------------- */
.cua__info {
    border: 2px solid #ddd;
    padding: 1.5em;
    font-size: .8em;
}

.cua__info span {
    font-size: 1.8em;
    display: block;
}

/* -----------------------------------------------
 * 4.7 Upload Documents
 * -------------------------------------------- */
.doc-upload__container {
    border: 2px solid #ddd;
    background: #F2F2F2;
    padding: 0;
    position: relative;
}

.doc-upload__container:before {
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    width: 16.66666667%; /* same width as "col-2" */
    background: #fff;
    left: 0;
    z-index: 0;
}

.doc-upload__container:after {
    position: absolute;
    content: "";
    display: block;
    height: 100%;
    width: 2px;
    background: #ddd;
    left: 16.66666667%; /* same width as "col-2" */
    z-index: 2;
}

@media screen and (max-width: 991px) {
    .doc-upload__container:after {
        left: 25%; /* same width as "col-4" */
    }
    .doc-upload__container:before {
        width: 25%; /* same width as "col-4" */
    }
}

@media screen and (max-width: 767px) {
    .doc-upload__container:after {
        left: 33.33333%; /* same width as "col-3" */
    }
    .doc-upload__container:before {
        width: 33.33333%; /* same width as "col-3" */
    }
}

/**/
.document-upload-tmp .doc-upload__left {
    width: 33.33333% !important;
}

.document-upload-tmp .doc-upload__right {
    width: 66.66666667% !important;
}

.document-upload-tmp .doc-upload__container:before {
    width: 33.33333%; /* same width as "col-2" */
}

.document-upload-tmp .doc-upload__container:after {
    left: 33.33333%; /* same width as "col-2" */
}

/**/

.doc-upload__slide {
    opacity: 0;
    display: none;
}

.doc-upload__slide:after {
    content: '';
    display: block;
    clear: both;
}

.doc-upload__container .loading-spinner {
    position: absolute;
    background: rgba(0, 0, 0, .2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: auto;
    height: auto;
}

.doc-upload__container .loading-spinner img {
    width: 40px;
    position: absolute;
    left: 50%;
    margin-left: -20px;
    top: 50%;
    margin-top: -20px;
}

.doc-upload__slide--is-active {
    display: block;
    opacity: 1;

    animation: fadeInFromNone 0.5s ease-out;
}

@keyframes fadeInFromNone {
    0% {
        display: none;
        opacity: 0
    }
    1% {
        display: block;
        opacity: 0
    }
    100% {
        display: block;
        opacity: 1
    }
}

.doc-upload__left,
.doc-upload__right {
    padding: 1.5em;
}

.doc-upload__left {
    background: #fff;
}

.doc-upload__label {
    font-weight: bold;
    font-size: 1.2em;
}

.doc-upload__fieldset {
    text-align: center;
    overflow: hidden;
    margin-bottom: 2.5em;
}

.doc-upload__choosefile {
    position: relative;
    display: block;
    background: #fff;
    padding: 1.5em 1em 1em;
    border: 1px solid #ddd;
}

.doc-upload--error {
    background: #F2DEDF;
}

.doc-upload__options {
    display: block;
}

.doc-upload__choosefile--notloaded {
    cursor: pointer;
}

.doc-upload__choosefile--notloaded:hover a.doc-upload__choosetext {
    text-decoration: none;
}

.doc-upload__input {
    cursor: inherit;
    display: block;
    font-size: 999px;
    min-height: 100%;
    min-width: 100%;
    opacity: 0;
    position: absolute;
    right: 0;
    text-align: right;
    top: 0;
}

.doc-upload__icon {
    font-size: 4em;
    display: block;
    color: #e8e8e8;
}

.doc-upload__icon--green {
    color: #19B309;
    margin-bottom: 10px;
}

.doc-upload--error .doc-upload__icon {
    color: #EACACF;
}

a.doc-upload__choosetext {
    font-size: 1.2em;
    margin-top: 1em;
    display: inline-block;
    text-decoration: underline;
    color: #999;
}

.doc-upload__choosetext:hover {
    text-decoration: none;
}

.doc-upload__separator {
    display: inline-block;
    padding: 0 .5em;
    font-size: 1.3em;
}

.doc-upload__submit {
    transition: background .2s;
}

.doc-upload__back {
    margin-right: 10px;
}

.doc-upload__fieldset .loading-spinner {
    position: absolute;
    background: rgba(0, 0, 0, .2);
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3;
    width: auto;
    height: auto;
}

.doc-upload__fieldset .loading-spinner img {
    width: 30px;
    position: absolute;
    left: 50%;
    margin-left: -15px;
    top: 50%;
    margin-top: -15px;
}

/* -----------------------------------------------
 * 4.8 Step Counter
 * -------------------------------------------- */
// TODO(Davit): Move this into StepCounter.scss
.step-counter {
    .step-counter-bar-status {
        background: $color-highlight-primary;
    }

    .step-counter-num {
        background: $color-highlight-primary;
    }
}

/* -----------------------------------------------
 * 4.9 Submit Modal
 * -------------------------------------------- */
.submit-modal {
    text-align: center;
    padding: 4em;
}

.submit-modal h5 {
    text-align: center;
}

.submit-modal__icon {
    display: block;
    color: rgb(255, 255, 255);
    background: #f9b31d;
    width: 65px;
    height: 65px;
    text-align: center;
    font-size: 3em;
    border-radius: 50%;
    padding-top: 16px;
    margin: 0 auto 1.2em;
}

/* -----------------------------------------------
 * 4.9 Cookie Notice
 * -------------------------------------------- */
.alert.cookie-notice {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    background-color: white;
    color: gray;
    text-align: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    border:none;
    margin: 0px;
    z-index:9999;

    p, button {
        padding-top: 10px;
        margin-bottom: 10px;
    }

    a {
        font-weight: $typography-main-weight-bold;
    }

    button {
        margin-right: 20px;
        float: none;
    }
}

/* -----------------------------------------------
 * 4.10 Details Display Well (Card Transactions App)
 * -------------------------------------------- */

.bottom-row .btn.btn-xs.btn-success {
    background-color: $color-message-success-medium;
}

/* -----------------------------------------------
 * 5. App Webview Overrides
 * -------------------------------------------- */

#main-wrap.main-wrap-webview {
    margin: 0 !important;
    padding: 21px 0 42px !important;
}

#main-wrap.main-wrap-webview #content-wrap {
    margin: 0 !important;
    padding: 0 !important;
}

/* Autocomplete */
.react-autosuggest__suggestions-container--open {
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12)
}

.react-autosuggest__suggestions-container--open,
.rrui__select__options {
    background-color: $color-form-background;
    border-color: $color-form-border;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}

.react-autosuggest__suggestion,
.rrui__expandable__content--expanded {
    background-color: $color-form-background;
    color: $color-form-font;
}

.react-autosuggest__suggestion--highlighted,
button.rrui__select__option--focused,
button.rrui__select__option--focused:hover,
button.rrui__select__option:hover {
    background-color: $color-highlight-primary-light-3;
    color: $color-form-font-highlight;
}

/* Onfido */

#onfido-mount {
    button.onfido-sdk-ui-Button-button-outline {
        border: 1px solid $color-highlight-primary;
        color: $color-highlight-primary;
    }

    button.onfido-sdk-ui-Theme-btn-primary,
    button.onfido-sdk-ui-Camera-btn.onfido-sdk-ui-Camera-fullScreenBtn,
    button.onfido-sdk-ui-Button-button-primary {
        background: $color-highlight-primary;
        color: $color-button-font;

        &:hover {
            background-color: $color-highlight-primary-shade-1;
        }
    }

    a.onfido-sdk-ui-crossDevice-CrossDeviceLink-copyToClipboard {
        border-bottom: 1px solid $color-highlight-primary;
    }

    span.onfido-sdk-ui-CameraPermissions-Primer-allow::after {
        border: 4px solid $color-highlight-primary;
    }

    div.onfido-sdk-ui-Collapsible-trigger {
        text-decoration-color: $color-highlight-primary;
    }

    div.onfido-sdk-ui-Error-container-warning {
        background-color: $color-highlight-primary;
    }
    span.onfido-sdk-ui-CameraPermissions-Recover-recovery {
        background: $color-highlight-primary;
    }
}

.onfido-sdk-ui-Uploader-instructions .onfido-sdk-ui-Theme-icon.onfido-sdk-ui-Uploader-icon.onfido-sdk-ui-Uploader-identityIcon {
    -webkit-mask: url('#{$image-path}/onfido-assets/upload.svg') no-repeat 50% 50%;
    mask: url('#{$image-path}/onfido-assets/upload.svg') no-repeat 50% 50%;
    background: $color-highlight-primary;
}

.onfido-shell-wrapper {
    .onfido-shell-title {
        color: $color-highlight-primary;
        font-weight: $typography-main-weight-bold;
    }

    button.onfido-button {
        background-color: $color-highlight-primary;
        color: $color-button-font;

        &:disabled,
        &:disabled:hover {
            background-color: $color-gray;
        }

        &:hover {
            background-color: $color-highlight-primary-shade-1;
        }
    }
}

@import "print";
